import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {TarokkaComponent} from './tarokka/tarokka/tarokka.component';
import {CesiumViewComponent} from './cesium/cesium-view/cesium-view.component';
import {ChartViewComponent} from './chart/chart-view/chart-view/chart-view.component';
import {Resume2Component} from './resume/resume2/resume2.component';
import {ShoppingCartComponent} from './shopping-cart/shopping-cart.component';

const routes: Routes = [
  { path: '', component: Resume2Component },
  { path: 'tarokka', component: TarokkaComponent },
  { path: 'cesium', component: CesiumViewComponent },
  { path: 'charts', component: ChartViewComponent },
  { path: 'shopping-cart', component: ShoppingCartComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
