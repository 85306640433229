<div class="row">
  <div class="col-md-2">

    <button pButton pRipple type="button" class="p-button-outlined" style="width:100%" (click)="changeDisplay(0)"
            label="&nbsp;Line">
      <img src="https://img.icons8.com/material-sharp/96/000000/line-chart.png" alt="Line" width="32" height="32" title="Line"/>
    </button>
    <button pButton pRipple type="button" class="p-button-outlined" style="width:100%" (click)="changeDisplay(1)"
            label="&nbsp;Bar">
      <img
        src="assets/icons/bar-chart.svg"
        alt="Bar" width="32" height="32" title="Bar">
    </button>

    <button pButton pRipple type="button" class="p-button-outlined" style="width:100%" (click)="changeDisplay(2)"
            label="&nbsp;Bubble">
      <img src="assets/icons/bubbles.svg"
           alt="Bubble" width="32" height="32" title="Bubble">
    </button>


    <button pButton pRipple type="button" class="p-button-outlined" style="width:100%" (click)="changeDisplay(3)"
            label="&nbsp;Doughnut">
      <img src="https://img.icons8.com/windows/32/000000/doughnut-chart.png" alt="Doughnut" width="32" height="32" title="Doughnut"/>
    </button>

    <button pButton pRipple type="button" class="p-button-outlined" style="width:100%" (click)="changeDisplay(4)"
            label="&nbsp;Pie">
      <img
        src="assets/icons/pie-chart.svg"
        alt="Pie" width="32" height="32" title="Pie">
    </button>

    <button pButton pRipple type="button" class="p-button-outlined" style="width:100%" (click)="changeDisplay(5)"
            label="&nbsp;Radar">
      <img src="https://img.icons8.com/fluent-systems-regular/96/000000/radar-plot.png" alt="Radar" width="32" height="32" title="Radar"/>
    </button>

  </div>

  <div class="col-md-10">
    <span [hidden]="displayChart[0]">
      <app-line-chart></app-line-chart>
    </span>
    <span [hidden]="displayChart[1]">
      <app-bar-chart></app-bar-chart>
    </span>
    <span [hidden]="displayChart[2]">
      <app-bubble-chart></app-bubble-chart>
    </span>
    <span [hidden]="displayChart[3]">
      <app-doughnut-chart></app-doughnut-chart>
    </span>
    <span [hidden]="displayChart[4]">
      <app-pie-chart></app-pie-chart>
    </span>
    <span [hidden]="displayChart[5]">
      <app-radar-chart></app-radar-chart>
    </span>
  </div>
</div>













