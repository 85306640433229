import {Injectable, OnInit} from '@angular/core';
import {InfoModel} from './info.model';

import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InfoService {
  infoMap = new Map();
  public infoModel = new Subject<InfoModel>();

  constructor(){
    const tarokka = new InfoModel(
      "Tarokka",
      'Tarokka is a deck of cards used to tell the fortune to adventurers in a specific roleplaying scenario.  You can buy a tarokka deck, ' +
      'use a standard hoyle deck, or do what I did... code it.  In actuality, it\'s 2 decks.  A set of "common cards" make up slots 1, 2 and 3.  ' +
      'A set of "high cards" make up slots 4 and 5.  Common cards are straight forward, the same fortune gets told whether in slot 1, 2, or 3, ' +
      'but a different fortune is told for the high cards depending on which slot the card falls.' ,
      'On the technical side, a Deck Service is being injected into the Tarokka Component.  The Deck Service draws ' +
      'random cards from each deck for the appropriate slots, stores the information in an array which, using Card Models, stores all the appropriate text, image urls, ' +
      'and position info for each slot.  The card array is then used by a Subject<Card[]> class and notifies any listening Components to update ' +
      'their display appropriately.',
      "Notice, also, switching between the original layout and the summary layout is seamless and maintains state.  The original " +
      "layout is how the book tells you to perform the fortune.  The summary layout is simply a concise way to display the same information."
    )
    this.infoMap.set("tarokka", tarokka);

    const cesium = new InfoModel(
      "Cesium",
      'Cesium provides a canvas to display various data/imagery on the globe.  The information presented here is the top 300+ US cities ' +
      'by population.  It displays the amount of change between the 2010 US Census and the 2016 estimated population.' ,
      'The taller and larger the cylinder the greater the population of the city.  The deeper and darker the green represents a greater ' +
      'population increase by percentage.  The deeper and darker the red represents a greater population DECREASE over the same period of time.',
      ""
    )
    this.infoMap.set("cesium", cesium);

  }



  public setInfoModel(urlExtension:string) : void{
    if(urlExtension){
      var temp = this.infoMap.get(urlExtension.toLowerCase().replace("/", ""))
      if(temp) {
        this.infoModel.next(temp);
      } else {
        this.infoModel.next(undefined);
      }
    } else {
      this.infoModel.next(undefined);
    }

  }

}
