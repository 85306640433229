import {Component, OnInit} from '@angular/core';
import {ArrayUtil} from '../util/array.util';
import {ItemModel} from './item.model';
import {PrimeNGConfig, SelectItem} from 'primeng/api';
import {Product} from './product';
import {ProductService} from './product.service';

@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.scss']
})
export class ShoppingCartComponent implements OnInit {

  products: Product[] = [];
  sortOptions: SelectItem[] = [];
  sortOrder: number = -1;
  sortField: string = "";

  constructor(private productService: ProductService, private primengConfig: PrimeNGConfig) { }

  searchValue: string = "";

  shoes: ItemModel[] = [];
  shoes_9: ItemModel[] = [];

  ngOnInit() {
    this.productService.getProducts().then(data => this.products = data);

    this.primengConfig.ripple = true;

    this.sortOptions = [
      {label: 'Price High to Low', value: '!price'},
      {label: 'Price Low to High', value: 'price'}
    ];

    this.shoes = ArrayUtil.smartItemModels;
    this.shoes_9 = this.shoes.slice(0,9);
  }

  onSortChange(event: any) {
    let value = event.value;

    if (value.indexOf('!') === 0) {
      this.sortOrder = -1;
      this.sortField = value.substring(1, value.length);
    }
    else {
      this.sortOrder = 1;
      this.sortField = value;
    }
  }






}
