import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-resume2',
  templateUrl: './resume2.component.html',
  styleUrls: ['./resume2.component.scss']
})
export class Resume2Component{
  certDisplay:boolean = false;

  experienceSelected = [true, true, false, false, false, false, false];
  educationSelected = [true, false, false];

  constructor() { }

  displayCert(){
    this.certDisplay = true;
  }

  expandExperienceSection(){
    this.toggleDropdown(true, this.experienceSelected);
  }

  collapseExperienceSection(){
    this.toggleDropdown(false, this.experienceSelected);
  }

  expandEducationSection(){
    this.toggleDropdown(true, this.educationSelected);
  }

  collapseEducationSection(){
    this.toggleDropdown(false, this.educationSelected);
  }

  private toggleDropdown(setTo:boolean, boolarray: boolean[]){
    if(this.testAll(setTo, boolarray)){
      for (let i=0; i < boolarray.length; i++) boolarray[i] = !setTo;
    } else {
      for (let i=0; i < boolarray.length; i++) boolarray[i] = setTo;
    }
  }

  private testAll(testCase: boolean, array: boolean[]){
    for(let i=0; i < array.length; i++){
      if(array[i] != testCase){
        return false;
      }
    }
    return true;
  }
}


