<div class="row">
  <div class="col-md-12">
    <h3 style="text-align: center">2020 Madden KC Chiefs Ratings</h3>
  </div>
</div>

<div class="chart-wrapper">
  <canvas baseChart
          [datasets]="radarChartData"
          [options]="radarChartOptions"
          [labels]="radarChartLabels"
          [colors]="radarChartColors"
          [chartType]="radarChartType">
  </canvas>
</div>


<p-table [value]="players"
         selectionMode="multiple"
         [(selection)]="selectedPlayers"
         (onRowSelect)="playerSelected($event)"
         (onRowUnselect)="playerSelected($event)"
         [paginator]="true"
         [rows]="10"
         [rowsPerPageOptions]="[5,10,15,20]">
  <ng-template pTemplate="header">
    <tr>
      <th>NAME</th><th>POS</th><th>OVR</th><th>SPD</th><th>ACC</th><th>STR</th><th>AWR</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-player>
    <tr [pSelectableRow]="rowData">
      <td>{{player.NAME}}</td>
      <td>{{player.POS}}</td>
      <td>{{player.OVR}}</td>
      <td>{{player.SPD}}</td>
      <td>{{player.ACC}}</td>
      <td>{{player.STR}}</td>
      <td>{{player.AWR}}</td>
    </tr>
  </ng-template>
</p-table>

