<div class="row">
  <div class="col-md-12">
    <h3 style="text-align: center">My Three Sons Bi-Monthly Spending Habits</h3>
  </div>
</div>
<div class="chart-wrapper">
  <canvas baseChart
          [datasets]="lineChartData"
          [labels]="lineChartLabels"
          [options]="lineChartOptions"
          [legend]="lineChartLegend"
          chartType='line'
          [plugins]="lineChartPlugins">
  </canvas>
</div>

<button pButton type="button" class="p-button-outlined" style="width: 20%"
        (click)="generateNewData()"
        label="&nbsp;Redo">
  <img
    src="assets/icons/arrow-counterclockwise.svg"
    alt="Reset" width="32" height="32" title="Reset">
</button>
<!--<button type="button" pButton pRipple (click)="showSticky()" label="Sticky"></button>-->
<p-toast></p-toast>
