import { Component, } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';

@Component({
  selector: 'app-chart-view',
  templateUrl: './chart-view.component.html',
  styleUrls: ['./chart-view.component.scss']
})
export class ChartViewComponent {
  displayChart:boolean[] = [false, true, true, true, true, true];

  changeDisplay(position:number){
    for(let i=0; i < this.displayChart.length; i++){
      if(i == position){
        this.displayChart[i] = false;
      } else this.displayChart[i] = true;
    }
  }
}

