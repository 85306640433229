
<p-toolbar>
  <div class="ui-toolbar-group-left">
    <button pButton type="button" class="p-button-outlined"
            (click)="dealCard()"
            [disabled]="dealDisabled"
            label="{{dealButtonText}}">
      <img
        src="assets/icons/files.svg"
        alt="{{dealButtonText}}" width="32" height="32" title="{{dealButtonText}}">
    </button>
  </div>
  <div class="ui-toolbar-group-right">
    <div *ngIf="hideRedealAndSummary">
      <button pButton type="button" class="p-button-outlined"
              (click)="deal5()"
              label="Redeal">
        <img
          src="assets/icons/files.svg"
          alt="Redeal" width="32" height="32" title="Redeal">
      </button>
      <button pButton type="button" class="p-button-outlined"
              (click)="toggleSummary()"
              label="{{summaryButtonText}}">
        <img
          src="{{summaryUrl}}"
          alt="{{summaryButtonText}}" width="32" height="32" title="{{summaryButtonText}}">
      </button>
    </div>
  </div>
</p-toolbar>

<span [hidden]="showSummary">
<div class="row backgroundImg lightgray" style="min-height: 700px; margin-top:10px;">
  <div class="col-md-3">
    <div *ngIf="positionDesc">
      <h3>{{positionDesc}}</h3>
      <hr>
    </div>
    <div *ngIf="cardTitle">
      <h1>{{cardTitle}}</h1>
      <hr>
    </div>
    <div *ngIf="cardDescription">
      <h3>{{cardDescription}}</h3>
      <hr>
    </div>
    <div *ngIf="cardClue">
      <h3>The fortune teller rolls back her eyes and states: <br><br>{{cardClue}}</h3>
    </div>
  </div>
  <div class="col-md-3">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <img src="../../assets/img/transparentcard.png" height="209" width="150">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div (click)="selectPositionN(0)" class="card">
          <app-card [position]="0"></app-card>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <img src="../../assets/img/transparentcard.png" height="209" width="150">
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-3">
    <div class="row">
      <div class="col-md-12">
        <div (click)="selectPositionN(1)" class="card">
          <app-card [position]="1"></app-card>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div (click)="selectPositionN(4)" class="card">
          <app-card [position]="4"></app-card>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div (click)="selectPositionN(3)" class="card">
          <app-card [position]="3"></app-card>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-3">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <img src="../../assets/img/transparentcard.png" height="209" width="150">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div (click)="selectPositionN(2)" class="card">
          <app-card [position]="2"></app-card>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="card">
        <img src="../../assets/img/transparentcard.png" height="209" width="150">
      </div>
    </div>
  </div>
</div>
</span>
<span [hidden]="!showSummary">
  <div class="row backgroundImg lightgray" style="min-height: 700px; padding-top:10px; margin-top:10px;">
    <div class="col-md-1"></div>
    <div class="col-md-2">
      <div (click)="selectPositionN(0)">
        <app-card [position]="0"></app-card>
      </div>
      <div>
        <h5 style="text-align: right">{{POSITION_1_DESC}}</h5>
      </div>
      <div *ngIf="position1Title">
        <hr>
        <h3 style="text-align: right">{{position1Title}}</h3>
      </div>
      <div *ngIf="position1Desc">
        <hr>
        <h5 style="text-align: right">{{position1Desc}}</h5>
      </div>
      <div *ngIf="position1Clue">
        <hr>
        <h5 style="text-align: right">{{position1Clue}}</h5>
      </div>
    </div>
    <div class="col-md-2">
      <div (click)="selectPositionN(1)">
        <app-card [position]="1"></app-card>
      </div>
      <div>
        <h5 style="text-align: right">{{POSITION_2_DESC}}</h5>
      </div>
      <div *ngIf="position2Title">
        <hr>
        <h3 style="text-align: right">{{position2Title}}</h3>
      </div>
      <div *ngIf="position2Desc">
        <hr>
        <h5 style="text-align: right">{{position2Desc}}</h5>
      </div>
      <div *ngIf="position2Clue">
        <hr>
        <h5 style="text-align: right">{{position2Clue}}</h5>
      </div>
    </div>
    <div class="col-md-2">
      <div (click)="selectPositionN(2)">
        <app-card [position]="2"></app-card>
      </div>
      <div>
        <h5 style="text-align: right">{{POSITION_3_DESC}}</h5>
      </div>
      <div *ngIf="position3Title">
        <hr>
        <h3 style="text-align: right">{{position3Title}}</h3>
      </div>
      <div *ngIf="position3Desc">
        <hr>
        <h5 style="text-align: right">{{position3Desc}}</h5>
      </div>
      <div *ngIf="position3Clue">
        <hr>
        <h5 style="text-align: right">{{position3Clue}}</h5>
      </div>
    </div>
    <div class="col-md-2">
      <div (click)="selectPositionN(3)">
        <app-card [position]="3"></app-card>
      </div>
      <div>
        <h5 style="text-align: right">{{POSITION_4_DESC}}</h5>
      </div>
      <div *ngIf="position4Title">
        <hr>
        <h3 style="text-align: right">{{position4Title}}</h3>
        </div>
      <div *ngIf="position4Desc">
        <hr>
        <h5 style="text-align: right">{{position4Desc}}</h5>
      </div>
      <div *ngIf="position4Clue">
        <hr>
        <h5 style="text-align: right">{{position4Clue}}</h5>
      </div>
    </div>
    <div class="col-md-2">
      <div (click)="selectPositionN(4)">
        <app-card [position]="4"></app-card>
      </div>
      <div>
        <h5 style="text-align: right">{{POSITION_5_DESC}}</h5>
      </div>
      <div *ngIf="position5Title">
        <hr>
        <h3 style="text-align: right">{{position5Title}}</h3>
        </div>
      <div *ngIf="position5Desc">
        <hr>
        <h5 style="text-align: right">{{position5Desc}}</h5>
      </div>
      <div *ngIf="position5Clue">
        <hr>
        <h5 style="text-align: right">{{position5Clue}}</h5>
      </div>
    </div>
    <div class="col-md-1"></div>
  </div>

</span>
