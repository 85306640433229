export class CityModel {
  constructor (
    public id: number,
    public city: string,
    public state: string,
    public stateAbbrev: string,
    public estimate2019: number,
    public census2010: number,
    public percentChange: number,
    public sqMiLandArea2016: number,
    public sqKmLandArea2016: number,
    public sqMiPopDensity2016: number,
    public sqKmPopDensity2016: number,
    public latitude: number,
    public longitude: number){}
}
