import { Component, OnInit } from '@angular/core';
import {ChartDataSets} from 'chart.js';
import {Color, Label} from 'ng2-charts';
import {ArrayUtil} from '../../../util/array.util';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent implements OnInit {

  constructor(private messageService: MessageService) {
    this.generateNewData();
  }

  lineChartData: ChartDataSets[] = [];

  lineChartLabels: Label[] = ['January', ' ',
    'February',  ' ',
    'March', ' ',
    'April', ' ',
    'May', ' ',
    'June', ' ',
    'July', ' ',
    'August', ' ',
    'September', ' ',
    'October', ' ',
    'November', ' ',
    'December', ' '];

  lineChartOptions = {
    responsive: true,
  };

  lineChartColors = ArrayUtil.chartColors;

  lineChartLegend = true;
  lineChartPlugins = [];
  lineChartType = 'line';


  generateNewData(){

    const data1 = this.getWeeklySpendingData();
    const data2 = this.getWeeklySpendingData();
    const data3 = this.getWeeklySpendingData();

    this.lineChartData  = [
      { data: data1, label: 'Mike' },
      { data: data2, label: 'Robbie' },
      { data: data3, label: 'Chip' },
    ];

  }

  getWeeklySpendingData(){
    const returnArray:number[] = [];

    for(let i=0; i < 24; i++){
      returnArray.push(this.getRandomNumber());
    }

    return returnArray;
  }

  private getRandomNumber(){
    return Math.floor(Math.random() * 60) + 1
  }

  showSticky() {
    this.messageService.add({severity:'info', summary: 'Sticky', detail: 'Message Content', sticky: true});
  }

  ngOnInit(): void {
    this.showSticky();
  }
}
