import {Component, OnInit} from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import {CityPopulationService} from '../../../root-services/city-population.service';
import {ArrayUtil} from '../../../util/array.util';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent implements OnInit{

  startPercentile = 48;
  endPercentile = 52;

  barChartLabels: Label[] = [];
  barChartType: ChartType = 'bar';
  barChartLegend = true;
  barChartPlugins = [];

  barChartOptions: ChartOptions = {
    responsive: true,
  };

  rangeValues: number[] = [this.startPercentile, this.endPercentile];

  states: {name: string, code: string}[] = [];
  selectedStates: {name: string, code: string}[] = [];

  barChartData: ChartDataSets[] = [];
  barChartColors = ArrayUtil.chartColors;


  constructor(private cityService: CityPopulationService) {
    for (let s of ArrayUtil.states){
      this.states.push({
          name: s.name,
          code: s.abbreviation
        });
    }

    this.cityService.citiesSubject.subscribe(cities => {
      const data1 = [];
      const data2 = [];
      this.barChartLabels = [];

      for(let c of cities){
        this.barChartLabels.push(c.city + ", " + c.stateAbbrev);
        data1.push(c.estimate2019);
        data2.push(c.census2010);
      }

      this.barChartData = [
        { data: data2, label: 'Census 2010' },
        { data: data1, label: 'Estimated Population 2019' },
      ];
    });
  }


  ngOnInit(): void {
    this.cityService.getSlice(this.startPercentile, this.endPercentile);
  }

  reset(){
    this.selectedStates = [];
    this.rangeValues = [this.startPercentile, this.endPercentile];
    this.cityService.getSlice(this.startPercentile, this.endPercentile);
  }

  onStateChange(newValue:any){
    this.rangeValues = [0, 100];
    this.cityService.sortByStateWithRange(this.selectedStates, this.rangeValues[0], this.rangeValues[1]);
  }

  onSliderChange(newValue:any){
    this.cityService.sortByStateWithRange(this.selectedStates, this.rangeValues[0], this.rangeValues[1]);
  }





}
