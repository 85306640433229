<!--<div class="row">-->
<!--  <div class="col-lg-12">-->
<!--    <button (click)="getTop10()">Top 10</button>-->
<!--    <button (click)="deleteNYC()">Delete NYC</button>-->
<!--  </div>-->
<!--</div>-->
<ac-layer acFor="let entity of entities$" [context]="this">
  <ac-cylinder-desc props="{
														position: entity.position,
														length : entity.length,
                            topRadius : entity.radius,
                            bottomRadius : entity.radius,
                            material : entity.material,
                            outline: Cesium.Color.BLACK.withAlpha(.6)
														}">
  </ac-cylinder-desc>
</ac-layer>
