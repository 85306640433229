import { Component, OnInit, Input, Output } from '@angular/core';
import {InfoModel} from '../../info/info.model';
import {InfoService} from '../../info/info.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  hideButton = true;
  display = false;
  info = new InfoModel("", "", "", "");

  constructor(private infoService: InfoService) { }

  ngOnInit(): void {
    this.infoService.infoModel.subscribe((infoModel) =>{

      if(infoModel === undefined){
        this.hideButton = true;
      } else {
        this.hideButton = false;
        this.info.title = infoModel.title;
        this.info.contentA = infoModel.contentA;
        this.info.contentB = infoModel.contentB;
        this.info.contentC = infoModel.contentC;
      }
    })
  }

  showBasicDialog() {
   this.display = !this.display;
  }

  forwardToCourseProject(){
    window.open(
    "https://ng-course-recipe-book-e81d6.firebaseapp.com/", "_blank");
  }
}

