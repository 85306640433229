import {Component, OnInit, ViewChild} from '@angular/core';
import {AcLayerComponent, AcNotification, ActionType, CameraService, MapEventsManagerService, SceneMode} from 'angular-cesium';
import {Observable, Subscriber} from 'rxjs';
import {CylinderDataService} from './cylinder/cylinder-data.service';

@Component({
  selector: 'app-cesium-view',
  templateUrl: './cesium-view.component.html',
  styleUrls: ['./cesium-view.component.scss']
})
export class CesiumViewComponent implements OnInit {

  constructor(private cameraService: CameraService) {
  }

  ngOnInit(): void {
    //
    // const camera = this.cameraService.getCamera();
    const centerOfUSA = new Cesium.Cartesian3.fromDegrees(-98.5795, 39.8283, 2000000.0);
    // camera.position = new Cesium.Cartesian3();

    // PERFORMANCE_SCENE2D - columbos view with 2D restrications, better perfornace then SCENE2D.
    // this.cameraService.setSceneMode(SceneMode.SCENE3D);

    // Get cesium camera
    // const camera = this.cameraService.getCamera();

    // Zoom out
    // this.cameraService.getCamera().zoomOut(50000);
    //
    // // Zoom in
    // this.cameraService.getCamera().zoomIn(10000);

  }


}
