import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, NavigationError, NavigationStart, Router} from '@angular/router';
import {InfoService} from './info/info.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'brad-pirochta';

  constructor(private route: ActivatedRoute, private router: Router, private infoService:InfoService) {
  }

  ngOnInit(): void {

    this.router.events.subscribe((val) => {
      // if (val instanceof NavigationStart) {
      //
      // }

      if (val instanceof NavigationEnd) {
        this.infoService.setInfoModel(val.urlAfterRedirects);
      }

      if (val instanceof NavigationError) {
        console.log(val.error);
      }

    });
  }
}
