import {Injectable} from '@angular/core';
import {Plane} from '../model/plane.model';
import {Observable, Subject} from 'rxjs';
import {AcNotification, ActionType} from 'angular-cesium';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PlanesService{
  public planes: Subject<Plane[]> = new Subject<Plane[]>();

  constructor() {
  }

  private planesArray = <Plane[]>[
    {
      id: '1',
      name: 'Airbus a320',
      image: 'https://cdn3.iconfinder.com/data/icons/airport-collection/100/23-512.png'
    },
    {
      id: '2',
      name: 'Boeing 777',
      image: 'https://cdn1.iconfinder.com/data/icons/fly-airbus-and-aeroplane/154/fly-air-plane-airbus-aeroplane-512.png'
    }
  ];

  getPlanes() {
    this.planes.next(this.planesArray);
  }

}
