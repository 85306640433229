<div class="container" style="padding: 0px 0px;">
  <div class="row">
    <div class="col-md-12">
      <img src="assets/img/s-mart.png" alt="S-Mart" width="130" height="32" title="Shop smart.  Shop S-Mart."
           style="margin:12px 10px 12px 0px;">
      <span class="p-input-icon-right">
        <i class="pi pi-search"></i>
        <input type="text" pInputText class="search-input" style="border: 1px solid lightgray; margin:0px 0px 0px 0px;" placeholder="Search" [(ngModel)]="searchValue" />
      </span>
      <button pButton pRipple
              type="button"
              class="p-button shoppingCartButton"
              style="float: right;"
              label="&nbsp;Cart (0)">
        <img src="assets/icons/cart3.svg" alt="Cart" width="32" height="32" title="Cart">
      </button>
    </div>
  </div>
  <p-toolbar>
    <div class="ui-toolbar-group-left">
      <button pButton pRipple
              type="button"
              class="p-button shoppingCartHeaderButton"
              label="Best Sellers"
              (mouseenter)="bs.toggle($event)"
              (mouseleave)="bs.toggle($event)">

      </button>
      <button pButton pRipple
              type="button"
              class="p-button shoppingCartHeaderButton"
              label="Just For You"
              (mouseenter)="jfy.toggle($event)"
              (mouseleave)="jfy.toggle($event)">
      </button>
      <button pButton pRipple
              type="button"
              class="p-button shoppingCartHeaderButton"
              label="New Releases"
              (mouseenter)="nr.toggle($event)"
              (mouseleave)="nr.toggle($event)">
      </button>
      <button pButton pRipple
              type="button"
              class="p-button shoppingCartHeaderButton"
              label="On Sale"
              (mouseenter)="os.toggle($event)"
              (mouseleave)="os.toggle($event)">
      </button>
      <div
        #overlayTarget
        style="width: 200px; height: 30px; margin-top: -30px;"
      ></div>
    </div>
  </p-toolbar>
<!--  <div class="row">-->
<!--    <div class="col-md-3" style="border-right: 1px solid lightgray; margin-top: 10px;">-->
<!--      asdfasdf-->
<!--    </div>-->
<!--    <div class="col-md-9" >-->
        <p-dataView #dv [value]="products" [paginator]="true" [rows]="9" filterBy="name" [sortField]="sortField" [sortOrder]="sortOrder" layout="grid">
<!--          <ng-template pTemplate="header">-->
<!--            <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between">-->
<!--              <p-dropdown [options]="sortOptions" placeholder="Sort By Price" (onChange)="onSortChange($event)" styleClass="p-mb-2 p-mb-md-0"></p-dropdown>-->
<!--              <span class="p-input-icon-left p-mb-2 p-mb-md-0">-->
<!--                  <i class="pi pi-search"></i>-->
<!--                  <input type="search" pInputText placeholder="Search by Name">-->
<!--              </span>-->
<!--              <p-dataViewLayoutOptions></p-dataViewLayoutOptions>-->
<!--            </div>-->
<!--          </ng-template>-->
<!--          <ng-template let-product pTemplate="listItem">-->
<!--            <div class="p-col-12">-->
<!--              <div class="product-list-item">-->
<!--                <div class="product-list-detail">-->
<!--                  <div class="product-name">{{product.name}}</div>-->
<!--                  <div class="product-description">{{product.description}}</div>-->
<!--                  <p-rating [ngModel]="product.rating" [readonly]="true" [cancel]="false"></p-rating>-->
<!--                  <i class="pi pi-tag product-category-icon"></i><span class="product-category">{{product.category}}</span>-->
<!--                </div>-->
<!--                <div class="product-list-action">-->
<!--                  <span class="product-price">${{product.price}}</span>-->
<!--                  <p-button icon="pi pi-shopping-cart" label="Add to Cart" [disabled]="product.inventoryStatus === 'OUTOFSTOCK'"></p-button>-->
<!--                  <span [class]="'product-badge status-' + product.inventoryStatus.toLowerCase()">{{product.inventoryStatus}}</span>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </ng-template>-->
<!--          <ng-template let-product pTemplate="gridItem">-->
<!--            <div class="p-col-12 p-md-4">-->
<!--              <div class="product-grid-item card">-->
<!--                <div class="product-grid-item-top">-->
<!--                  <div>-->
<!--                    <i class="pi pi-tag product-category-icon"></i>-->
<!--                    <span class="product-category">{{product.category}}</span>-->
<!--                  </div>-->
<!--                  <span [class]="'product-badge status-' + product.inventoryStatus.toLowerCase()">{{product.inventoryStatus}}</span>-->
<!--                </div>-->
<!--                <div class="product-grid-item-content">-->
<!--                  <div class="product-name">{{product.name}}</div>-->
<!--                  <img class="product-detail-img" [src]=product.image [alt]="product.name"/>-->
<!--                  <div class="product-description">{{product.description}}</div>-->
<!--                  <p-rating [ngModel]="product.rating" [readonly]="true" [cancel]="false"></p-rating>-->
<!--                </div>-->
<!--                <div class="product-grid-item-bottom">-->
<!--                  <span class="product-price">${{product.price}}</span>-->
<!--                  <p-button icon="pi pi-shopping-cart" [disabled]="product.inventoryStatus === 'OUTOFSTOCK'"></p-button>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </ng-template>-->
        </p-dataView>
<!--      </div>-->
<!--    </div>-->


<!--    <div class="col-md-9" style="margin-top: 10px;">-->
<!--      <p-dataView [value]="shoes"  [paginator]="true" [rows]="9" filterBy="title"-->
<!--                  [sortField]="sortField" [sortOrder]="sortOrder" layout="grid">-->
<!--        <p-header>-->
<!--          <p-dataViewLayoutOptions></p-dataViewLayoutOptions>-->
<!--        </p-header>-->
<!--        <ng-template let-shoe pTemplate="listItem">-->
<!--          <div>-->
<!--            {{shoe.id}}-->
<!--          </div>-->
<!--        </ng-template>-->
<!--        <ng-template let-shoe pTemplate="gridItem">-->
<!--            <div class="shoe-grid-item card">-->
<!--              <div class="shoe-grid-item-top">-->
<!--                <div>-->
<!--                  <i class="pi pi-tag product-category-icon"></i>-->
<!--                  <span class="product-category">{{shoe.category}}</span>-->
<!--                </div>-->
<!--                <span [class]="'product-badge status-' + shoe.inventoryStatus.toLowerCase()">{{shoe.inventoryStatus}}</span>-->
<!--              </div>-->
<!--              <div class="grid-detail-item-content">-->
<!--                <img class="grid-detail-img" [src]="shoe.imgUrl" [alt]="shoe.title"/>-->
<!--                <div class="shoe-name">{{shoe.title}}</div>-->
<!--                <div class="shoe-description">{{shoe.description}}</div>-->
<!--              </div>-->
<!--              <div class="shoe-grid-item-bottom">-->
<!--                <span class="product-price">${{shoe.price}}</span>-->
<!--                <p-button icon="pi pi-shopping-cart" [disabled]="shoe.inventoryStatus === 'OUTOFSTOCK'"></p-button>-->
<!--              </div>-->
<!--          </div>-->
<!--        </ng-template>-->
<!--      </p-dataView>-->
<!--    </div>-->
</div>

<p-overlayPanel #bs
                [style]="{width: '240px'}"
                [appendTo]="'body'">
  <ng-template pTemplate>
    <app-item-display></app-item-display>
  </ng-template>
</p-overlayPanel>

<p-overlayPanel #jfy [style]="{width: '240px'}"
                [appendTo]="'body'">
  <ng-template pTemplate>
    <app-item-display></app-item-display>
  </ng-template>
</p-overlayPanel>

<p-overlayPanel #nr [style]="{width: '240px'}"
                [appendTo]="'body'">
  <ng-template pTemplate>
    <app-item-display></app-item-display>
  </ng-template>
</p-overlayPanel>

<p-overlayPanel #os [style]="{width: '240px'}"
                [appendTo]="'body'">
  <ng-template pTemplate>
    <app-item-display></app-item-display>
  </ng-template>
</p-overlayPanel>
