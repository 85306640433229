<div class="chart-wrapper">
  <canvas baseChart
          [datasets]="bubbleChartData"
          [options]="bubbleChartOptions"
          [legend]="bubbleChartLegend"
          [chartType]="bubbleChartType">
  </canvas>
</div>
<button pButton type="button" class="p-button-outlined" style="width: 20%"
        (click)="generateNewData()"
        label="&nbsp;Redo">
  <img
    src="assets/icons/arrow-counterclockwise.svg"
    alt="Reset" width="32" height="32" title="Reset">
</button>

