export class InfoModel {
  constructor(private _title:string, private _contentA:string, private _contentB:string, private _contentC:string) {
  }

  get title(): string {
    return this._title;
  }

  set title(value: string) {
    this._title = value;
  }

  get contentA(): string {
    return this._contentA;
  }

  set contentA(value: string) {
    this._contentA = value;
  }

  get contentB(): string {
    return this._contentB;
  }

  set contentB(value: string) {
    this._contentB = value;
  }

  get contentC(): string {
    return this._contentC;
  }

  set contentC(value: string) {
    this._contentC = value;
  }
}
