import {Injectable, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CylinderDataModel} from './cylinder-data.model';
import {from, Observable, Subject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class CylinderDataService implements OnInit{
  constructor(private http: HttpClient) {}

  public subject = new Subject<any[]>();
  public observable = this.subject.asObservable();
  private cylinders:any[] = [];

  public newCylinder = new Subject<any[]>()

  get$(amount = 5) {
    const staticEntities = this.myEntities();
    return from(staticEntities);
  }

  getTop10$(){
    const staticEntities = this.myEntities().slice(0, 1);
    return from(staticEntities);
  }

  private myEntities() {
    const staticEntities = [];

    const cities = this.myCities();
    for(let c of cities){
      length = this.getLength(c);
      staticEntities.push({
        id: c.id.toString(),
        position: Cesium.Cartesian3.fromDegrees(c.longitude, c.latitude, length/2),
        length: length,
        radius : this.getRadius(c),
        material: this.getMaterial(c)
        // material: Cesium.Color.fromCssColorString('#1E90FF').withAlpha(.4)
      })
    }

    return staticEntities;
  }

  getLength(x: CylinderDataModel){
    const scalePercentage = x.estimate2019/8336817;
    return 900000 * scalePercentage + 100000;
  }

  getMaterial(x: CylinderDataModel){
    const highestLoss = -7.43;
    const highestThird = highestLoss * 0.666667;
    const lowestThird = highestLoss * 0.333333;

    const highestGain = 0.71;
    const topThird = highestGain * 0.666667;
    const bottomThird = highestGain * 0.333333;

    if(x.percentChange < 0){
      if(x.percentChange < highestThird) {return Cesium.Color.FIREBRICK.withAlpha(1);}
      else if(x.percentChange < lowestThird) {return Cesium.Color.LIGHTCORAL.withAlpha(.6);}
      else return Cesium.Color.LIGHTPINK.withAlpha(.2);
    } else {
      if(x.percentChange > topThird)  {return Cesium.Color.DARKGREEN.withAlpha(1);}
      else if(x.percentChange > bottomThird) {return Cesium.Color.MEDIUMSEAGREEN.withAlpha(.6);}
      else return Cesium.Color.PALEGREEN.withAlpha(.2);
    }
  }

  getRadius(x: CylinderDataModel){
    const scalePercentage = x.estimate2019/8336817;
    return 64000.0 * scalePercentage + 16000.0;

  }

  private myCities() {
    const cities = [new CylinderDataModel(1,"New York City","New York",8336817,8175133,0.02,301.5,781,28317,10933,40.6635,-73.9387),
      new CylinderDataModel(2,"Los Angeles","California",3979576,3792621,0.05,468.7,1214,8484,3276,34.0194,-118.4108),
      new CylinderDataModel(3,"Chicago","Illinois",2693976,2695598,-0.06,227.3,589,11900,4600,41.8376,-87.6818),
      new CylinderDataModel(4,"Houston","Texas",2320268,2100263,0.1,637.5,1651,3613,1395,29.7866,-95.3909),
      new CylinderDataModel(5,"Phoenix","Arizona",1680992,1445632,0.16,517.6,1341,3120,1200,33.5722,-112.0901),
      new CylinderDataModel(6,"Philadelphia","Pennsylvania",1584064,1526006,0.04,134.2,348,11683,4511,40.0094,-75.1333),
      new CylinderDataModel(7,"San Antonio","Texas",1547253,1327407,0.17,461,1194,3238,1250,29.4724,-98.5251),
      new CylinderDataModel(8,"San Diego","California",1423851,1307402,0.09,325.2,842,4325,1670,32.8153,-117.135),
      new CylinderDataModel(9,"Dallas","Texas",1343573,1197816,0.12,340.9,883,3866,1493,32.7933,-96.7665),
      new CylinderDataModel(10,"San Jose","California",1021795,945942,0.08,177.5,460,5777,2231,37.2967,-121.8189),
      new CylinderDataModel(11,"Austin","Texas",978908,790390,0.24,312.7,810,3031,1170,30.3039,-97.7544),
      new CylinderDataModel(12,"Jacksonville","Florida",911507,821784,0.11,747.4,1936,1178,455,30.3369,-81.6616),
      new CylinderDataModel(13,"FortWorth","Texas",909585,741206,0.23,342.9,888,2491,962,32.7815,-97.3467),
      new CylinderDataModel(14,"Columbus","Ohio",898553,787033,0.14,218.5,566,3936,1520,39.9852,-82.9848),
      new CylinderDataModel(15,"Charlotte","North Carolina",885708,731424,0.21,305.4,791,2757,1064,35.2078,-80.831),
      new CylinderDataModel(16,"San Francisco","California",881549,805235,0.09,46.9,122,18569,7170,37.7272,-123.0322),
      new CylinderDataModel(17,"Indianapolis","Indiana",876384,820445,0.07,361.5,936,2366,914,39.7767,-86.1459),
      new CylinderDataModel(18,"Seattle","Washington",753675,608660,0.24,83.8,217,8405,3245,47.6205,-122.3509),
      new CylinderDataModel(19,"Denver","Colorado",727211,600158,0.21,153.3,397,4521,1746,39.7619,-104.8811),
      new CylinderDataModel(20,"Washington","District of Columbia",705749,601723,0.17,61.1,158,11148,4304,38.9041,-77.0172),
      new CylinderDataModel(21,"Boston","Massachusetts",692600,617594,0.12,48.3,125,13938,5381,42.332,-71.0202),
      new CylinderDataModel(22,"El Paso","Texas",681728,649121,0.05,256.8,665,2660,1030,31.8484,-106.427),
      new CylinderDataModel(23,"Nashville","Tennessee",670820,601222,0.12,475.9,1233,1388,536,36.1718,-86.785),
      new CylinderDataModel(24,"Detroit","Michigan",670031,713777,-6.13,138.8,360,4847,1871,42.383,-83.1022),
      new CylinderDataModel(25,"Oklahoma City","Oklahoma",655057,579999,0.13,606.3,1570,1053,407,35.4671,-97.5137),
      new CylinderDataModel(26,"Portland","Oregon",654741,583776,0.12,133.5,346,4793,1851,45.537,-122.65),
      new CylinderDataModel(27,"Las Vegas","Nevada",651319,583756,0.12,134.4,348,4709,1818,36.2292,-115.2601),
      new CylinderDataModel(28,"Memphis","Tennessee",651073,646889,0.01,317.4,822,2056,794,35.1028,-89.9774),
      new CylinderDataModel(29,"Louisville","Kentucky",617638,597337,0.03,263.5,683,2339,903,38.1654,-85.6474),
      new CylinderDataModel(30,"Baltimore","Maryland",593490,620961,-4.42,80.9,210,7598,2934,39.3,-76.6105),
      new CylinderDataModel(31,"Milwaukee","Wisconsin",590157,594833,-0.79,96.2,249,6186,2388,43.0633,-87.9667),
      new CylinderDataModel(32,"Albuquerque","New Mexico",560513,545852,0.03,188.2,487,2972,1147,35.1056,-106.6474),
      new CylinderDataModel(33,"Tucson","Arizona",548073,520116,0.05,230.8,598,2299,888,32.1531,-110.8706),
      new CylinderDataModel(34,"Fresno","California",531576,494665,0.07,114.4,296,4563,1762,36.7836,-119.7934),
      new CylinderDataModel(35,"Mesa","Arizona",518012,439041,0.18,137.9,357,3514,1357,33.4019,-111.7174),
      new CylinderDataModel(36,"Sacramento","California",513624,466488,0.1,97.9,254,5059,1953,38.5666,-121.4686),
      new CylinderDataModel(37,"Atlanta","Georgia",506811,420003,0.21,133.5,346,3539,1366,33.7629,-84.4227),
      new CylinderDataModel(38,"KansasCity","Missouri",495327,459787,0.08,315,816,1528,590,39.1251,-94.551),
      new CylinderDataModel(39,"ColoradoSprings","Colorado",478221,416427,0.15,195.6,507,2378,918,38.8673,-104.7607),
      new CylinderDataModel(40,"Omaha","Nebraska",478192,408958,0.17,133.2,345,3356,1296,41.2644,-96.0451),
      new CylinderDataModel(41,"Raleigh","North Carolina",474069,403892,0.17,145.1,376,3163,1221,35.8306,-78.6418),
      new CylinderDataModel(42,"Miami","Florida",467963,399457,0.17,36,93,12599,4865,25.7752,-80.2086),
      new CylinderDataModel(43,"LongBeach","California",462628,462257,0,50.3,130,9347,3609,33.8092,-118.1553),
      new CylinderDataModel(44,"VirginiaBeach","Virginia",449974,437994,0.03,244.7,634,1850,710,36.78,-76.0252),
      new CylinderDataModel(45,"Oakland","California",433031,390724,0.11,55.9,145,7514,2901,37.7698,-122.2257),
      new CylinderDataModel(46,"Minneapolis","Minnesota",429606,382578,0.12,54,140,7660,2960,44.9633,-93.2683),
      new CylinderDataModel(47,"Tulsa","Oklahoma",401190,391906,0.02,196.8,510,2048,791,36.1279,-95.9023),
      new CylinderDataModel(48,"Tampa","Florida",399700,335709,0.19,113.4,294,3326,1284,27.9701,-82.4797),
      new CylinderDataModel(49,"Arlington","Texas",398854,365438,0.09,95.8,248,4100,1600,32.7007,-97.1247),
      new CylinderDataModel(50,"New Orleans","Louisiana",390144,343829,0.13,169.4,439,2311,892,30.0534,-89.9345),
      new CylinderDataModel(51,"Wichita ","Kansas",389938,382368,0.02,160.4,415,2431,939,37.6907,-97.3459),
      new CylinderDataModel(52,"Bakersfield","California",384145,347483,0.11,148.8,385,2529,976,35.3212,-119.0183),
      new CylinderDataModel(53,"Cleveland","Ohio",381009,396815,-3.98,77.7,201,4965,1917,41.4785,-81.6794),
      new CylinderDataModel(54,"Aurora","Colorado",379289,325078,0.17,153.5,398,2356,910,39.688,-104.6897),
      new CylinderDataModel(55,"Anaheim","California",350365,336265,0.04,50,130,7021,2711,33.8555,-117.7601),
      new CylinderDataModel(56,"Honolulu","Hawaii",345064,337256,0.02,60.5,157,5815,2245,21.3243,-157.8476),
      new CylinderDataModel(57,"Santa Ana","California",332318,324528,0.02,27.1,70,12333,4762,33.7363,-117.883),
      new CylinderDataModel(58,"Riverside","California",331360,303871,0.09,81.2,210,3999,1544,33.9381,-117.3932),
      new CylinderDataModel(59,"CorpusChristi","Texas",326586,305215,0.07,174.6,452,1866,720,27.7543,-97.1734),
      new CylinderDataModel(60,"Lexington","Kentucky",323152,295803,0.09,283.6,735,1123,434,38.0407,-84.4583),
      new CylinderDataModel(61,"Henderson","Nevada",320189,257729,0.24,104.7,271,2798,1080,36.0097,-115.0357),
      new CylinderDataModel(62,"Stockton","California",312697,291707,0.07,61.7,160,4977,1922,37.9763,-121.3133),
      new CylinderDataModel(63,"SaintPaul","Minnesota",308096,285068,0.08,52,135,5815,2245,44.9489,-93.1041),
      new CylinderDataModel(64,"Cincinnati","Ohio",303940,296943,0.02,77.4,201,3860,1490,39.1402,-84.5058),
      new CylinderDataModel(65,"St.Louis","Missouri",300576,319294,-5.86,62,161,5023,1939,38.6357,-90.2446),
      new CylinderDataModel(66,"Pittsburgh","Pennsylvania",300286,305704,-1.77,55.4,144,5481,2116,40.4398,-79.9766),
      new CylinderDataModel(67,"Greensboro","North Carolina",296710,269666,0.1,128.3,332,2237,864,36.0951,-79.827),
      new CylinderDataModel(68,"Lincoln","Nebraska",289102,258379,0.12,92.1,239,3044,1175,40.8105,-96.6803),
      new CylinderDataModel(69,"Anchorage","Alaska",288000,291826,-1.31,1706.6,4420,175,68,61.1743,-149.2843),
      new CylinderDataModel(70,"Plano","Texas",287677,259841,0.11,71.7,186,3990,1540,33.0508,-96.7479),
      new CylinderDataModel(71,"Orlando","Florida",287442,238300,0.21,105.2,273,2635,1017,28.4166,-81.2736),
      new CylinderDataModel(72,"Irvine","California",287401,212375,0.35,65.6,170,4057,1566,33.6784,-117.7713),
      new CylinderDataModel(73,"New ark","New Jersey",282011,277140,0.02,24.1,62,11691,4514,40.7242,-74.1726),
      new CylinderDataModel(74,"Durham","North Carolina",278993,228330,0.22,109.8,284,2395,925,35.9811,-78.9029),
      new CylinderDataModel(75,"ChulaVista","California",274492,243916,0.13,49.6,129,5387,2080,32.6277,-117.0152),
      new CylinderDataModel(76,"Toledo","Ohio",272779,287208,-5.02,80.7,209,3451,1332,41.6641,-83.5819),
      new CylinderDataModel(77,"Fort Wayne","Indiana",270402,253691,0.07,110.6,287,2391,923,41.0882,-85.1439),
      new CylinderDataModel(78,"St. Petersburg","Florida",265351,244769,0.08,61.8,160,4223,1631,27.762,-82.6441),
      new CylinderDataModel(79,"Laredo","Texas",262491,236091,0.11,101.1,262,2544,982,27.5604,-99.4892),
      new CylinderDataModel(80,"JerseyCity","New Jersey",262075,247597,0.06,14.8,38,17848,6891,40.7114,-74.0648),
      new CylinderDataModel(81,"Chandler","Arizona",261165,236123,0.11,64.9,168,3813,1472,33.2829,-111.8549),
      new CylinderDataModel(82,"Madison","Wisconsin",259680,233209,0.11,77,199,3280,1270,43.0878,-89.4299),
      new CylinderDataModel(83,"Lubbock","Texas",258862,229573,0.13,124.6,323,2027,783,33.5656,-101.8867),
      new CylinderDataModel(84,"Scottsdale","Arizona",258069,217385,0.19,183.9,476,1341,518,33.6843,-111.8611),
      new CylinderDataModel(85,"Reno","Nevada",255601,225221,0.13,107.3,278,2286,883,39.5491,-119.8499),
      new CylinderDataModel(86,"Buffalo","New York",255284,261310,-2.31,40.4,105,6359,2455,42.8925,-78.8597),
      new CylinderDataModel(87,"Gilbert","Arizona",254114,208453,0.22,68,176,3487,1346,33.3103,-111.7431),
      new CylinderDataModel(88,"Glendale","Arizona",252381,226721,0.11,59.1,153,4161,1607,33.5331,-112.1899),
      new CylinderDataModel(89,"North Las Vegas","Nevada",251974,216961,0.16,98,254,2436,941,36.2857,-115.0939),
      new CylinderDataModel(90,"Winston–Salem","North Carolina",247945,229617,0.08,132.5,343,1828,706,36.1027,-80.261),
      new CylinderDataModel(91,"Chesapeake","Virginia",244835,222209,0.1,338.5,877,703,271,36.6794,-76.3018),
      new CylinderDataModel(92,"Norfolk","Virginia",242742,242803,-0.03,53.3,138,4599,1776,36.923,-76.2446),
      new CylinderDataModel(93,"Fremont","California",241110,214089,0.13,77.5,201,3008,1161,37.4945,-121.9412),
      new CylinderDataModel(94,"Garland","Texas",239928,226876,0.06,57,148,4122,1592,32.9098,-96.6303),
      new CylinderDataModel(95,"Irving","Texas",239798,216290,0.11,67,174,3557,1373,32.8577,-96.97),
      new CylinderDataModel(96,"Hialeah","Florida",233339,224669,0.04,21.5,56,10995,4245,25.8699,-80.3029),
      new CylinderDataModel(97,"Richmond","Virginia",230436,204214,0.13,59.8,155,3732,1441,37.5314,-77.476),
      new CylinderDataModel(98,"Boise","Idaho",228959,205671,0.11,82.1,213,2718,1049,43.6002,-116.2317),
      new CylinderDataModel(99,"Spokane","Washington",222081,208916,0.06,68.7,178,3144,1214,47.6669,-117.4333),
      new CylinderDataModel(100,"BatonRouge","Louisiana",220236,229493,-4.03,85.9,223,2651,1024,30.4422,-91.1309),
      new CylinderDataModel(101,"Tacoma","Washington",217827,198397,0.1,49.7,129,4251,1641,47.2522,-122.4598),
      new CylinderDataModel(102,"San Bernardino","California",215784,209924,0.03,61.5,159,3516,1358,34.1416,-117.2936),
      new CylinderDataModel(103,"Modesto","California",215196,201165,0.07,43,111,4934,1905,37.6375,-121.003),
      new CylinderDataModel(104,"Fontana","California",214547,196069,0.09,43,111,4876,1883,34.109,-117.4629),
      new CylinderDataModel(105,"Des Moines","Iowa",214237,203433,0.05,88.9,230,2424,936,41.5726,-93.6102),
      new CylinderDataModel(106,"Moreno Valley","California",213055,193365,0.1,51.3,133,4006,1547,33.9233,-117.2057),
      new CylinderDataModel(107,"Santa Clarita","California",212979,176320,0.21,52.8,137,3446,1331,34.403,-118.5042),
      new CylinderDataModel(108,"Fayetteville","North Carolina",211657,200564,0.06,147.7,383,1386,535,35.0828,-78.9735),
      new CylinderDataModel(109,"Birmingham","Alabama",209403,212237,-1.34,146.1,378,1452,561,33.5274,-86.799),
      new CylinderDataModel(110,"Oxnard","California",208881,197899,0.06,26.9,70,7729,2984,34.2023,-119.2046),
      new CylinderDataModel(111,"Rochester","New York",205695,210565,-2.31,35.8,93,5835,2253,43.1699,-77.6169),
      new CylinderDataModel(112,"Port St. Lucie","Florida",201846,164603,0.23,118.9,308,1557,601,27.2806,-80.3883),
      new CylinderDataModel(113,"Grand Rapids","Michigan",201013,188040,0.07,44.4,115,4424,1708,42.9612,-85.6556),
      new CylinderDataModel(114,"Huntsville","Alabama",200574,180105,0.11,213.4,553,905,349,34.699,-86.673),
      new CylinderDataModel(115,"Salt Lake City","Utah",200567,186440,0.08,111.2,288,1742,673,40.7769,-111.931),
      new CylinderDataModel(116,"Frisco","Texas",200490,116989,0.71,67.7,175,2417,933,33.1554,-96.8226),
      new CylinderDataModel(117,"Yonkers","New York",200370,195976,0.02,18,47,11156,4307,40.9459,-73.8674),
      new CylinderDataModel(118,"Amarillo","Texas",199371,190695,0.05,101.4,263,1968,760,35.1999,-101.8302),
      new CylinderDataModel(119,"Glendale","California",199303,191719,0.04,30.4,79,6606,2551,34.1814,-118.2458),
      new CylinderDataModel(120,"Huntington Beach","California",199223,189992,0.05,26.9,70,7459,2880,33.6906,-118.0093),
      new CylinderDataModel(121,"McKinney","Texas",199177,131117,0.52,63,163,2735,1056,33.1985,-96.668),
      new CylinderDataModel(122,"Montgomery","Alabama",198525,205764,-3.52,159.8,414,1252,483,32.3472,-86.2661),
      new CylinderDataModel(123,"Augusta","Georgia",197888,195844,0.01,302.5,784,652,252,33.3655,-82.0734),
      new CylinderDataModel(124,"Aurora","Illinois",197757,197899,-0.07,44.9,116,4479,1729,41.7635,-88.2901),
      new CylinderDataModel(125,"Akron","Ohio",197597,199110,-0.76,62,161,3188,1231,41.0805,-81.5214),
      new CylinderDataModel(126,"Little Rock","Arkansas",197312,193524,0.02,118.7,307,1673,646,34.7254,-92.3586),
      new CylinderDataModel(127,"Tempe","Arizona",195805,161719,0.21,40,104,4562,1761,33.3884,-111.9318),
      new CylinderDataModel(128,"Columbus","Georgia",195769,189885,0.03,216.4,561,913,353,32.5102,-84.8749),
      new CylinderDataModel(129,"Overland Park","Kansas",195494,173372,0.13,75.1,195,2516,971,38.889,-94.6906),
      new CylinderDataModel(130,"Grand Prairie","Texas",194543,175396,0.11,72.3,187,2637,1018,32.6869,-97.0211),
      new CylinderDataModel(131,"Tallahassee","Florida",194500,181376,0.07,100.4,260,1901,734,30.4551,-84.2534),
      new CylinderDataModel(132,"Cape Coral","Florida",194495,154305,0.26,105.6,274,1703,658,26.6432,-81.9974),
      new CylinderDataModel(133,"Mobile","Alabama",188720,195111,-3.28,139.4,361,1384,534,30.6684,-88.1002),
      new CylinderDataModel(134,"Knoxville","Tennessee",187603,178874,0.05,98.5,255,1891,730,35.9707,-83.9493),
      new CylinderDataModel(135,"Shreveport","Louisiana",187112,199311,-6.12,107.1,277,1820,700,32.4669,-93.7922),
      new CylinderDataModel(136,"Worcester","Massachusetts",185428,181045,0.02,37.4,97,4933,1905,42.2695,-71.8078),
      new CylinderDataModel(137,"Ontario","California",185010,163924,0.13,49.9,129,3471,1340,34.0394,-117.6042),
      new CylinderDataModel(138,"Vancouver","Washington",184463,161791,0.14,46.9,122,3728,1439,45.6349,-122.5957),
      new CylinderDataModel(139,"SiouxFalls","South Dakota",183793,153888,0.19,75.4,195,2312,893,43.5383,-96.732),
      new CylinderDataModel(140,"Chattanooga","Tennessee",182799,167674,0.09,143.1,371,1241,479,35.066,-85.2484),
      new CylinderDataModel(141,"Brownsville","Texas",182781,175023,0.04,132.5,343,1387,536,25.9991,-97.455),
      new CylinderDataModel(142,"Fort Lauderdale","Florida",182437,165521,0.1,34.6,90,5166,1995,26.1412,-80.1467),
      new CylinderDataModel(143,"Providence","RhodeIsland",179883,178042,0.01,18.4,48,9740,3760,41.8231,-71.4188),
      new CylinderDataModel(144,"Newport News","Virginia",179225,180719,-0.83,69.1,179,2631,1016,37.0762,-76.522),
      new CylinderDataModel(145,"Rancho Cucamonga","California",177603,165269,0.07,40,104,4413,1704,34.1233,-117.5642),
      new CylinderDataModel(146,"Santa Rosa","California",176753,167815,0.05,41.3,107,4241,1637,38.4468,-122.7061),
      new CylinderDataModel(147,"Peoria","Arizona",175961,154065,0.14,175.7,455,934,361,33.7862,-112.308),
      new CylinderDataModel(148,"Oceanside","California",175742,167086,0.05,41.3,107,4249,1641,33.2245,-117.3062),
      new CylinderDataModel(149,"Elk Grove","California",174775,153015,0.14,42.2,109,4022,1553,38.4146,-121.385),
      new CylinderDataModel(150,"Salem","Oregon",174365,154637,0.13,48.6,126,3445,1330,44.9237,-123.0232),
      new CylinderDataModel(151,"Pembroke Pines","Florida",173591,154019,0.13,33,86,5109,1973,26.021,-80.3404),
      new CylinderDataModel(152,"Eugene","Oregon",172622,156185,0.11,44.1,114,3777,1458,44.0567,-123.1162),
      new CylinderDataModel(153,"Garden Grove","California",171644,170883,0,18,47,9714,3751,33.7788,-117.9605),
      new CylinderDataModel(154,"Cary","North Carolina",170282,135234,0.26,56.5,146,2873,1109,35.7809,-78.8133),
      new CylinderDataModel(155,"Fort Collins","Colorado",170243,143986,0.18,55.8,145,2943,1136,40.5482,-105.0648),
      new CylinderDataModel(156,"Corona","California",169868,152374,0.11,39.5,102,4222,1630,33.862,-117.5655),
      new CylinderDataModel(157,"Springfield","Missouri",167882,159498,0.05,82.3,213,2033,785,37.1942,-93.2913),
      new CylinderDataModel(158,"Jackson","Mississippi",160628,173514,-7.43,111,288,1524,588,32.3158,-90.2128),
      new CylinderDataModel(159,"Alexandria","Virginia",159428,139966,0.14,15,39,10387,4010,38.8201,-77.0841),
      new CylinderDataModel(160,"Hayward","California",159203,144186,0.1,45.5,118,3493,1349,37.6287,-122.1024),
      new CylinderDataModel(161,"Clarksville","Tennessee",158146,132929,0.19,98.3,255,1529,590,36.5664,-87.3452),
      new CylinderDataModel(162,"Lakewood","Colorado",157935,142980,0.1,42.9,111,3599,1390,39.6989,-105.1176),
      new CylinderDataModel(163,"Lancaster","California",157601,156633,0.01,94.3,244,1698,656,34.6936,-118.1753),
      new CylinderDataModel(164,"Salinas","California",155465,150441,0.03,23.6,61,6662,2572,36.6902,-121.6337),
      new CylinderDataModel(165,"Palmdale","California",155079,152750,0.02,106,275,1484,573,34.591,-118.1054),
      new CylinderDataModel(166,"Hollywood","Florida",154817,140768,0.1,27.3,71,5568,2150,26.031,-80.1646),
      new CylinderDataModel(167,"Springfield","Massachusetts",153606,153060,0,31.9,83,4830,1860,42.1155,-72.54),
      new CylinderDataModel(168,"Macon","Georgia",153159,91351,0.68,249.3,646,612,236,32.8088,-83.6942),
      new CylinderDataModel(169,"Kansas City","Kansas",152960,145786,0.05,124.8,323,1216,470,39.1225,-94.7418),
      new CylinderDataModel(170,"Sunnyvale","California",152703,140081,0.09,22,57,6944,2681,37.3858,-122.0263),
      new CylinderDataModel(171,"Pomona","California",151691,149058,0.02,23,60,6630,2560,34.0585,-117.7611),
      new CylinderDataModel(172,"Killeen","Texas",151666,127921,0.19,53.5,139,2680,1030,31.0777,-97.732),
      new CylinderDataModel(173,"Escondido","California",151625,143911,0.05,37.1,96,4087,1578,33.1331,-117.074),
      new CylinderDataModel(174,"Pasadena","Texas",151227,149043,0.01,43.5,113,3525,1361,29.6586,-95.1506),
      new CylinderDataModel(175,"Naperville","Illinois",148449,141853,0.05,38.7,100,3802,1468,41.7492,-88.162),
      new CylinderDataModel(176,"Bellevue","Washington",148164,122363,0.21,33.5,87,4221,1630,47.5979,-122.1565),
      new CylinderDataModel(177,"Joliet","Illinois",147344,147433,-0.06,64.4,167,2302,889,41.5177,-88.1488),
      new CylinderDataModel(178,"Murfreesboro","Tennessee",146900,108755,0.35,55.9,145,2360,910,35.8522,-86.416),
      new CylinderDataModel(179,"Midland","Texas",146038,111147,0.31,74.4,193,1809,698,32.0246,-102.1135),
      new CylinderDataModel(180,"Rockford","Illinois",145609,152871,-4.75,63.5,165,2325,898,42.2588,-89.0646),
      new CylinderDataModel(181,"Paterson","New Jersey",145233,146199,-0.66,8.4,22,17500,6800,40.9148,-74.1628),
      new CylinderDataModel(182,"Savannah","Georgia",144464,136286,0.06,103.6,268,1417,547,32.0025,-81.1536),
      new CylinderDataModel(183,"Bridgeport","Connecticut",144399,144229,0,16.1,42,9064,3500,41.1874,-73.1958),
      new CylinderDataModel(184,"Torrance","California",143592,145438,-1.27,20.5,53,7180,2770,33.835,-118.3414),
      new CylinderDataModel(185,"McAllen","Texas",143268,129877,0.1,58.4,151,2435,940,26.2322,-98.2464),
      new CylinderDataModel(186,"Syracuse","New York",142327,145170,-1.96,25,65,5735,2214,43.041,-76.1436),
      new CylinderDataModel(187,"Surprise","Arizona",141664,117517,0.21,107.9,280,1230,470,33.6706,-112.4527),
      new CylinderDataModel(188,"Denton","Texas",141541,113383,0.25,93.4,242,1433,553,33.2166,-97.1414),
      new CylinderDataModel(189,"Roseville","California",141500,118788,0.19,43,111,3085,1191,38.769,-121.3189),
      new CylinderDataModel(190,"Thornton","Colorado",141464,118772,0.19,35.7,93,3829,1478,39.9194,-104.9428),
      new CylinderDataModel(191,"Miramar","Florida",141191,122041,0.16,29.4,76,4709,1818,25.977,-80.3358),
      new CylinderDataModel(192,"Pasadena","California",141029,137122,0.03,23,60,6176,2385,34.1606,-118.1396),
      new CylinderDataModel(193,"Mesquite","Texas",140937,139824,0.01,47.2,122,3045,1176,32.7629,-96.5888),
      new CylinderDataModel(194,"Olathe","Kansas",140545,125872,0.12,60.9,158,2225,859,38.8843,-94.8195),
      new CylinderDataModel(195,"Dayton","Ohio",140407,141527,-0.79,55.7,144,2522,974,39.7774,-84.1996),
      new CylinderDataModel(196,"Carrollton","Texas",139248,119097,0.17,36.3,94,3674,1419,32.9884,-96.8998),
      new CylinderDataModel(197,"Waco","Texas",139236,124805,0.12,89,231,1510,580,31.5601,-97.186),
      new CylinderDataModel(198,"Orange","California",138669,136416,0.02,25.4,66,5532,2136,33.787,-117.8613),
      new CylinderDataModel(199,"Fullerton","California",138632,135161,0.03,22.4,58,6282,2425,33.8857,-117.928),
      new CylinderDataModel(200,"Charleston","South Carolina",137566,120083,0.15,109,282,1233,476,32.8179,-79.959),
      new CylinderDataModel(201,"West Valley City","Utah",135248,129480,0.04,35.5,92,3847,1485,40.6885,-112.0118),
      new CylinderDataModel(202,"Visalia","California",134605,124442,0.08,37.5,97,3495,1349,36.3273,-119.3289),
      new CylinderDataModel(203,"Hampton","Virginia",134510,137436,-2.13,51.5,133,2629,1015,37.048,-76.2971),
      new CylinderDataModel(204,"Gainesville","Florida",133997,124354,0.08,62.3,161,2112,815,29.6788,-82.3461),
      new CylinderDataModel(205,"Warren","Michigan",133943,134056,-0.08,34.4,89,3928,1517,42.4929,-83.025),
      new CylinderDataModel(206,"Coral Springs","Florida",133759,121096,0.1,23.8,62,5465,2110,26.2707,-80.2593),
      new CylinderDataModel(207,"Cedar Rapids","Iowa",133562,126326,0.06,70.8,183,1852,715,41.967,-91.6778),
      new CylinderDataModel(208,"Round Rock","Texas",133372,99887,0.34,35.6,92,3396,1311,30.5252,-97.666),
      new CylinderDataModel(209,"Sterling Heights","Michigan",132438,129699,0.02,36.5,95,3628,1401,42.5812,-83.0303),
      new CylinderDataModel(210,"Kent","Washington",132319,92411,0.43,33.7,87,3784,1461,47.388,-122.2127),
      new CylinderDataModel(211,"Columbia","South Carolina",131674,129272,0.02,133.5,346,1006,388,34.0291,-80.898),
      new CylinderDataModel(212,"Santa Clara","California",130365,116468,0.12,18.4,48,6845,2643,37.3646,-121.9679),
      new CylinderDataModel(213,"New Haven","Connecticut",130250,129779,0,18.7,48,6948,2683,41.3108,-72.925),
      new CylinderDataModel(214,"Stamford","Connecticut",129638,122643,0.06,37.6,97,3434,1326,41.0799,-73.546),
      new CylinderDataModel(215,"Concord","California",129295,122067,0.06,30.5,79,4221,1630,37.9722,-122.0016),
      new CylinderDataModel(216,"Elizabeth","New Jersey",129216,124969,0.03,12.3,32,10459,4038,40.6664,-74.1935),
      new CylinderDataModel(217,"Athens","Georgia",126913,115452,0.1,116.4,302,1060,410,33.9496,-83.3701),
      new CylinderDataModel(218,"Thousand Oaks","California",126813,126683,0,55.2,143,2335,902,34.1933,-118.8742),
      new CylinderDataModel(219,"Lafayette","Louisiana",126185,120623,0.05,53.8,139,2372,916,30.2074,-92.0285),
      new CylinderDataModel(220,"Simi Valley","California",125613,124237,0.01,41.5,108,3044,1175,34.2669,-118.7485),
      new CylinderDataModel(221,"Topeka","Kansas",125310,127473,-1.70,61.5,159,2062,796,39.0347,-95.6962),
      new CylinderDataModel(222,"Norman","Oklahoma",124880,110925,0.13,178.8,463,683,264,35.2406,-97.3453),
      new CylinderDataModel(223,"Fargo","North Dakota",124662,105549,0.18,49.3,128,2450,950,46.8652,-96.829),
      new CylinderDataModel(224,"Wilmington","North Carolina",123744,106476,0.16,51.6,134,2278,880,34.2092,-77.8858),
      new CylinderDataModel(225,"Abilene","Texas",123420,117063,0.05,106.7,276,1146,442,32.4545,-99.7381),
      new CylinderDataModel(226,"Odessa","Texas",123334,99940,0.23,45.2,117,2608,1007,31.8838,-102.3411),
      new CylinderDataModel(227,"Columbia","Missouri",123195,108500,0.14,65,168,1856,717,38.9515,-92.328638),
      new CylinderDataModel(228,"Pearland","Texas",122460,91252,0.34,46.3,120,2453,947,29.5558,-95.3231),
      new CylinderDataModel(229,"Victorville","California",122385,115903,0.06,73.3,190,1668,644,34.5277,-117.3536),
      new CylinderDataModel(230,"Hartford","Connecticut",122105,124775,-2.14,17.4,45,7083,2735,41.7659,-72.6816),
      new CylinderDataModel(231,"Vallejo","California",121692,115942,0.05,30.7,80,3951,1525,38.1079,-122.264),
      new CylinderDataModel(232,"Allentown","Pennsylvania",121442,118032,0.03,17.5,45,6882,2657,40.5936,-75.4784),
      new CylinderDataModel(233,"Berkeley","California",121363,112580,0.08,10.5,27,11547,4458,37.867,-122.2991),
      new CylinderDataModel(234,"Richardson","Texas",121323,99223,0.22,28.6,74,3963,1530,32.9723,-96.7081),
      new CylinderDataModel(235,"Arvada","Colorado",121272,106433,0.14,38.6,100,3043,1175,39.8337,-105.1503),
      new CylinderDataModel(236,"Ann Arbor","Michigan",119980,113934,0.05,28.1,73,4298,1659,42.2761,-83.7309),
      new CylinderDataModel(237,"Rochester","Minnesota",118935,106769,0.11,54.6,141,2088,806,44.0154,-92.4772),
      new CylinderDataModel(238,"Cambridge","Massachusetts",118927,105162,0.13,6.4,17,17289,6675,42.376,-71.1187),
      new CylinderDataModel(239,"Sugar Land","Texas",118488,78817,0.5,34,88,3488,1347,29.5994,-95.6142),
      new CylinderDataModel(240,"Lansing","Michigan",118210,114297,0.03,39.1,101,2967,1146,42.7143,-84.5593),
      new CylinderDataModel(241,"Evansville","Indiana",117979,117429,0,47.3,123,2526,975,37.9877,-87.5347),
      new CylinderDataModel(242,"College Station","Texas",117911,93857,0.26,51,132,2199,849,30.5852,-96.2964),
      new CylinderDataModel(243,"Fairfield","California",117133,105321,0.11,40.9,106,2806,1083,38.2593,-122.0321),
      new CylinderDataModel(244,"Clearwater","Florida",116946,107685,0.09,25.9,67,4415,1705,27.9789,-82.7666),
      new CylinderDataModel(245,"Beaumont","Texas",116825,118296,-1.24,82.1,213,1441,556,30.0849,-94.1453),
      new CylinderDataModel(246,"Independence","Missouri",116672,116830,-0.14,77.8,202,1504,581,39.0855,-94.3521),
      new CylinderDataModel(247,"Provo","Utah",116618,112488,0.04,41.7,108,2803,1082,40.2453,-111.6448),
      new CylinderDataModel(248,"West Jordan","Utah",116480,103712,0.12,32.3,84,3520,1360,40.6024,-112.0008),
      new CylinderDataModel(249,"Murrieta","California",116223,103466,0.12,33.6,87,3324,1283,33.5721,-117.1904),
      new CylinderDataModel(250,"Palm Bay","Florida",115552,103190,0.12,65.7,170,1676,647,27.9856,-80.6626),
      new CylinderDataModel(251,"El Monte","California",115487,113475,0.02,9.6,25,12063,4658,34.0746,-118.0291),
      new CylinderDataModel(252,"Carlsbad","California",115382,105328,0.1,37.7,98,3023,1167,33.1239,-117.2828),
      new CylinderDataModel(253,"North Charleston","South Carolina",115382,97471,0.18,73.7,191,1483,573,32.9178,-80.065),
      new CylinderDataModel(254,"Temecula","California",114761,100097,0.15,37.3,97,3031,1170,33.4931,-117.1317),
      new CylinderDataModel(255,"Clovis","California",114584,95631,0.2,24.2,63,4404,1700,36.8282,-119.6849),
      new CylinderDataModel(256,"Springfield","Illinois",114230,116250,-1.74,60.1,156,1925,743,39.7911,-89.6446),
      new CylinderDataModel(257,"Meridian","Idaho",114161,75092,0.52,29.8,77,3584,1384,43.6142,-116.3989),
      new CylinderDataModel(258,"Westminster","Colorado",113166,106114,0.07,31.7,82,3592,1387,39.8822,-105.0644),
      new CylinderDataModel(259,"Costa Mesa","California",113003,109960,0.03,15.7,41,7186,2775,33.6659,-117.9123),
      new CylinderDataModel(260,"High Point","North Carolina",112791,104371,0.08,55.2,143,2015,778,35.99,-79.9905),
      new CylinderDataModel(261,"Manchester","New Hampshire",112673,109565,0.03,33.1,86,3339,1289,42.9849,-71.4441),
      new CylinderDataModel(262,"Pueblo","Colorado",112361,106595,0.05,53.6,139,2058,795,38.2699,-104.6123),
      new CylinderDataModel(263,"Lakeland","Florida",112136,97422,0.15,65.9,171,1615,624,28.0555,-81.9549),
      new CylinderDataModel(264,"Pompano Beach","Florida",112118,99845,0.12,24,62,4558,1760,26.2416,-80.1339),
      new CylinderDataModel(265,"West Palm Beach","Florida",111955,99919,0.12,55.1,143,1963,758,26.7464,-80.1251),
      new CylinderDataModel(266,"Antioch","California",111502,102372,0.09,29.4,76,3772,1456,37.9791,-121.7962),
      new CylinderDataModel(267,"Everett","Washington",111475,103019,0.08,33.3,86,3275,1264,47.9566,-122.1914),
      new CylinderDataModel(268,"Downey","California",111126,111772,-0.58,12.4,32,9134,3527,33.9382,-118.1309),
      new CylinderDataModel(269,"Lowell","Massachusetts",110997,106519,0.04,13.6,35,8129,3139,42.639,-71.3211),
      new CylinderDataModel(270,"Centennial","Colorado",110937,100377,0.11,29.5,76,3727,1439,39.5906,-104.8691),
      new CylinderDataModel(271,"Elgin","Illinois",110849,108188,0.02,37.4,97,2998,1158,42.0396,-88.3217),
      new CylinderDataModel(272,"Richmond","California",110567,103701,0.07,30.1,78,3648,1409,37.9523,-122.3606),
      new CylinderDataModel(273,"Peoria","Illinois",110417,115007,-3.99,48.2,125,2371,915,40.7515,-89.6174),
      new CylinderDataModel(274,"Broken Arrow","Oklahoma",110198,98850,0.11,61.7,160,1741,672,36.0365,-95.781),
      new CylinderDataModel(275,"Miami Gardens","Florida",110001,107167,0.03,18.2,47,6212,2398,25.9489,-80.2436),
      new CylinderDataModel(276,"Billings","Montana",109577,104170,0.05,43.7,113,2525,975,45.7885,-108.5499),
      new CylinderDataModel(277,"Jurupa Valley","California",109527,0,0,42.9,111,2414,932,34.0026,-117.4676),
      new CylinderDataModel(278,"Sandy Springs","Georgia",109452,93853,0.17,37.7,98,2804,1083,33.9315,-84.3687),
      new CylinderDataModel(279,"Gresham","Oregon",109381,105594,0.04,23.3,60,4786,1848,45.5023,-122.4416),
      new CylinderDataModel(280,"Lewisville","Texas",109212,95290,0.15,36.7,95,2852,1101,33.0466,-96.9818),
      new CylinderDataModel(281,"Hillsboro","Oregon",109128,91611,0.19,25,65,4207,1624,45.528,-122.9357),
      new CylinderDataModel(282,"Ventura","California",109106,106433,0.03,21.8,57,5027,1941,34.2678,-119.2542),
      new CylinderDataModel(283,"Greeley","Colorado",108649,92889,0.17,47.8,124,2176,840,40.4153,-104.7697),
      new CylinderDataModel(284,"Inglewood","California",108151,109673,-1.39,9.1,24,12160,4700,33.9561,-118.3443),
      new CylinderDataModel(285,"Waterbury","Connecticut",107568,110366,-2.54,28.5,74,3799,1467,41.5585,-73.0367),
      new CylinderDataModel(286,"League City","Texas",107536,83560,0.29,51.2,133,1992,769,29.4901,-95.1091),
      new CylinderDataModel(287,"Santa Maria","California",107263,99553,0.08,22.8,59,4662,1800,34.9332,-120.4438),
      new CylinderDataModel(288,"Tyler","Texas",106985,96900,0.1,56.6,147,1852,715,32.3173,-95.3059),
      new CylinderDataModel(289,"Davie","Florida",106306,91992,0.16,34.9,90,2919,1127,26.0791,-80.285),
      new CylinderDataModel(290,"Lakewood","New Jersey",106300,92843,0.14,24.7,64,4079,1575,40.0771,-74.2004),
      new CylinderDataModel(291,"Daly City","California",106280,101123,0.05,7.6,20,14009,5409,37.7009,-122.465),
      new CylinderDataModel(292,"Boulder","Colorado",105673,97385,0.09,24.8,64,4358,1683,40.027,-105.2519),
      new CylinderDataModel(293,"Allen","Texas",105623,84246,0.25,27.1,70,3715,1434,33.0997,-96.6631),
      new CylinderDataModel(294,"West Covina","California",105101,106098,-0.94,16,41,6740,2600,34.0559,-117.9099),
      new CylinderDataModel(295,"Sparks","Nevada",105006,90264,0.16,35.9,93,2810,1080,39.5544,-119.7356),
      new CylinderDataModel(296,"Wichita Falls","Texas",104683,104553,0,72.2,187,1450,560,33.9067,-98.5259),
      new CylinderDataModel(297,"Green Bay","Wisconsin",104578,104057,0.01,45.4,118,2316,894,44.5207,-87.9842),
      new CylinderDataModel(298,"San Mateo","California",104430,97207,0.07,12.1,31,8592,3317,37.5603,-122.3106),
      new CylinderDataModel(299,"Norwalk","California",103949,105549,-1.52,9.7,25,10946,4226,33.9076,-118.0835),
      new CylinderDataModel(300,"Rialto","California",103526,99171,0.04,22.3,58,4633,1789,34.1118,-117.3883),
      new CylinderDataModel(301,"Las Cruces","New Mexico",103432,97618,0.06,76.9,199,1323,511,32.3264,-106.7897),
      new CylinderDataModel(302,"Chico","California",103301,86187,0.2,33.3,86,3102,1198,39.74,-121.8356),
      new CylinderDataModel(303,"El Cajon","California",102708,99478,0.03,14.5,38,7156,2763,32.8017,-116.9604),
      new CylinderDataModel(304,"Burbank","California",102511,103340,-0.80,17.4,45,6003,2318,34.1901,-118.3264),
      new CylinderDataModel(305,"South Bend","Indiana",102026,101168,0.01,41.4,107,2457,949,41.6769,-86.269),
      new CylinderDataModel(306,"Renton","Washington",101751,90927,0.12,23.4,61,4314,1666,47.4761,-122.192),
      new CylinderDataModel(307,"Vista","California",101638,93834,0.08,18.7,48,5436,2099,33.1895,-117.2386),
      new CylinderDataModel(308,"Davenport","Iowa",101590,99685,0.02,62.9,163,1631,630,41.5541,-90.604),
      new CylinderDataModel(309,"Edinburg","Texas",101170,77100,0.31,37.6,97,2691,1039,26.3042,-98.1639),
      new CylinderDataModel(310,"Tuscaloosa","Alabama",101129,90468,0.12,71.7,186,1399,540,33.2065,-87.5346),
      new CylinderDataModel(311,"Carmel","Indiana",101068,79191,0.28,47.5,123,2128,822,39.9667,-86.1),
      new CylinderDataModel(312,"Spokane Valley","Washington",101060,89755,0.13,37.7,98,2681,1035,47.6733,-117.2394),
      new CylinderDataModel(313,"San Angelo","Texas",101004,93200,0.08,59.9,155,1681,649,31.4411,-100.4505),
      new CylinderDataModel(314,"Vacaville","California",100670,92428,0.09,29,75,3449,1332,38.3539,-121.9728),
      new CylinderDataModel(315,"Clinton","Michigan",100471,96796,0.04,28.1,73,3573,1380,42.5903,-82.917),
      new CylinderDataModel(316,"Bend","Oregon",100421,76639,0.31,33.1,86,3034,1171,44.05,-121.3),
      new CylinderDataModel(317,"Woodbridge","New Jersey",100145,99585,0.01,23.3,60,4351,1680,40.5607,-74.2927)];

    return cities;
  }

  ngOnInit(): void {
  }
}
