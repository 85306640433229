<div class="row">
  <div class="col-md-12">
    <h3 style="text-align: center">2010 US Census City Population & 2019 Estimated US City Population</h3>
  </div>
</div>
<div class="chart-wrapper">
  <canvas baseChart
          [datasets]="barChartData"
          [labels]="barChartLabels"
          [options]="barChartOptions"
          [plugins]="barChartPlugins"
          [legend]="barChartLegend"
          [colors]="barChartColors"
          [chartType]="barChartType">
  </canvas>
</div>


<div class="row">
  <div class="col-md-3">
    <h4>Filter by State:</h4>
  </div>
  <div class="col-md-6">
    <h4>Percentile Range: {{rangeValues[0] + ' - ' + rangeValues[1]}}</h4>
  </div>
  <div class="col-md-3">
  </div>
</div>

<div class="row">
  <div class="col-md-3" style="margin-top: 13px;">
    <p-multiSelect [options]="states"
                   [(ngModel)]="selectedStates"
                   (ngModelChange)="onStateChange($event)"
                   [filter]="false"
                   optionLabel="name"></p-multiSelect>
  </div>
  <div class="col-md-6" style="margin-top: 25px;">
    <p-slider [(ngModel)]="rangeValues" [range]="true" (ngModelChange)="onSliderChange($event)"></p-slider>
  </div>
  <div class="col-md-3">
    <button pButton type="button" class="p-button-outlined" style="width: 100%"
            (click)="reset()"
            label="&nbsp;Reset">
      <img
        src="assets/icons/arrow-counterclockwise.svg"
        alt="Reset" width="32" height="32" title="Reset">
    </button>
  </div>
</div>

