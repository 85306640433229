<div class="row">
  <div class="col-md-12">
    <h3 style="text-align: center">US 2020 GDP per Capita by State</h3>
  </div>
</div>

<div class="chart-wrapper">
  <canvas baseChart
          [data]="doughnutChartData"
          [labels]="doughnutChartLabels"
          [chartType]="doughnutChartType">
  </canvas>
</div>

<div class="row">
  <div class="col-md-2">
  </div>
  <div class="col-md-8">
    <h4>Percentile Range: {{rangeValues[0] + ' - ' + rangeValues[1]}}</h4>
  </div>
  <div class="col-md-2">
  </div>
</div>

<div class="row">
  <div class="col-md-2">
  </div>
  <div class="col-md-6" style="margin-top: 25px;">
    <p-slider [(ngModel)]="rangeValues" [range]="true" (ngModelChange)="onSliderChange($event)"></p-slider>
  </div>
  <div class="col-md-2">
  </div>
</div>
