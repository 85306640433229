import { Component } from '@angular/core';
import { ChartDataSets, ChartType, RadialChartOptions } from 'chart.js';
import { Label } from 'ng2-charts';
import {MaddenPlayerModel} from '../../../models/madden-player.model';
import {ArrayUtil} from '../../../util/array.util';

@Component({
  selector: 'app-radar-chart',
  templateUrl: './radar-chart.component.html',
  styleUrls: ['./radar-chart.component.scss']
})
export class RadarChartComponent {

  players = ArrayUtil.maddenPlayers.slice();
  selectedPlayers:MaddenPlayerModel[] = [this.players[0]];

  public radarChartOptions: RadialChartOptions = {
    responsive: true,
  };

  public radarChartLabels: Label[] = ['OVR','SPD','ACC','STR','AWR','THP','CTH','CIT',
    'SPC','PAC','PUR','BCV','SPN','PWR','FMV','TAM','RBK','INJ','TKL','TRK','TAS','JUK','PRC','SRR','MRR',
    'DRR','JMP','TGH','TOR','MCV','ZCV','STF','REL','HP','KPW','KAC','BRS','IBL','STA','CAR','BTK','TUP',
    'PBK','PRS','TAD','BKS','AGI','ELU','KR'];

  public radarChartData: ChartDataSets[] = [
    { data: this.mapPlayerData(this.players[0]), label:  this.players[0].NAME + ", " + this.players[0].POS  }
  ];

  public radarChartType: ChartType = 'radar';

  radarChartColors = ArrayUtil.chartColors;

  playerSelected(e:any){
    this.radarChartData =[];
    for(let p of this.selectedPlayers){
      this.radarChartData.push(
        {
          data: this.mapPlayerData(p), label: p.NAME + ", " + p.POS
        }
      )
    }
  }

  mapPlayerData(player:MaddenPlayerModel){
    return [player.OVR,
      player.SPD,
      player.ACC,
      player.STR,
      player.AWR,
      player.THP,
      player.CTH,
      player.CIT,
      player.SPC,
      player.PAC,
      player.PUR,
      player.BCV,
      player.SPN,
      player.PWR,
      player.FMV,
      player.TAM,
      player.RBK,
      player.INJ,
      player.TKL,
      player.TRK,
      player.TAS,
      player.JUK,
      player.PRC,
      player.SRR,
      player.MRR,
      player.DRR,
      player.JMP,
      player.TGH,
      player.TOR,
      player.MCV,
      player.ZCV,
      player.STF,
      player.REL,
      player.HP,
      player.KPW,
      player.KAC,
      player.BRS,
      player.IBL,
      player.STA,
      player.CAR,
      player.BTK,
      player.TUP,
      player.PBK,
      player.PRS,
      player.TAD,
      player.BKS,
      player.AGI,
      player.ELU,
      player.KR]
  }




}
