import {Injectable} from '@angular/core';
import {Card, CommonCard, HighCard} from '../card/card/card.model';
import {CardState} from '../card/card/card-state.model';
import {Rank} from '../card/card/rank.model';
import {Suit} from '../card/card/suit.model';
import {Subject} from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class DeckService {
  IMAGE_DIR = "../assets/img/";

  position: Card[] = [];
  positionSubject: Subject<Card[]> = new Subject<Card[]>();
  selectedCard: Subject<Card> = new Subject<Card>();

  remainingCommonCards: CommonCard[] = [];
  remainingHighCards: HighCard[] = [];

  constructor() {
    this.resetDeck();
  }

  resetDeck(){
    this.remainingCommonCards = [
      new CommonCard(Rank.Master, Suit.Swords, "Warrior","" + "Strength & force " +
        "personified; violence; those who use force to accomplish their goals", "\"That which you seek lies " +
        "in the womb of darkness, the devil's lair: the one place to which he must return\"",
        this.IMAGE_DIR + "warrior.png"),
      new CommonCard(Rank.One, Suit.Swords, "Avenger","Justice & revenge for " +
        "great wrongs; those on a quest to rid the world of great evil", "\"The treasure lies in a dragon's " +
        "house, in hands once clean and now corrupted\"", this.IMAGE_DIR + "avenger.png"),
      new CommonCard(Rank.Two, Suit.Swords, "Paladin","Just & noble warriors; " +
        "those who live by a code of honor and integrity","\"I see a sleeping prince, a servant of light and " +
        "brother of darkness.  The treasure lies with him.\"",this.IMAGE_DIR + "paladin.png"),
      new CommonCard(Rank.Three, Suit.Swords, "Soldier","War & sacrifice; the " +
        "stamina to endure great hardship","\"Go to the mountains.  Climb the white tower guarded by golden " +
        "knights.\"",this.IMAGE_DIR + "soldier.png"),
      new CommonCard(Rank.Four, Suit.Swords, "Mercenary","Inner strength and " +
        "fortitude; those who fight for power or wealth","\"The thing you seek lies with the dead, under " +
        "mountains of gold coins.\"",this.IMAGE_DIR + "mercenary.png"),
      new CommonCard(Rank.Five, Suit.Swords, "Myrmidon","Great heroes; a sudden " +
        "reversal of fate; the triumph of the underdog over a mighty enemy","\"Look for a den of wolves in the " +
        "hills overlooking a mountain lake.  The treasure belongs to the Mother Night.\"",this.IMAGE_DIR
        + "myrmidon.png"),
      new CommonCard(Rank.Six, Suit.Swords, "Berserker","The brutal & barbaric " +
        "side of warfare; bloodlust; those with a bestial nature","\"Find the Mad Dog's crypt.  The treasure lies " +
        "within, beneath blackened bones.\"",this.IMAGE_DIR + "berzerker.png"),
      new CommonCard(Rank.Seven, Suit.Swords, "Hooded One","Bigotry; intolerance; " +
        "a mysterious presence or newcomer","\"I see a faceless god.  He awaits you at the end of a long and " +
        "winding road, deep in the mountains.\"",this.IMAGE_DIR + "hooded_one.png"),
      new CommonCard(Rank.Eight, Suit.Swords, "Dictator","All that is wrong " +
        "with government & leadership; those who rule through fear & violence","\"I see a throne fit " +
        "for a king.\"",this.IMAGE_DIR + "dictator.png"),
      new CommonCard(Rank.Nine, Suit.Swords, "Torturer","The coming of suffering " +
        "or merciless cruelty; one who is irredeemably evil or sadistic","\"There is a town where all is not well.  " +
        "There you will find a house of corruption, and within, a dark room full of still ghosts.\"",
        this.IMAGE_DIR + "torturer.png"),

      new CommonCard(Rank.Master, Suit.Stars, "Wizard","Mystery & riddles; the " +
        "unknown; those who crave magical power & great knowledge","\"Look for a wizard's tower on a lake.  Let " +
        "the Wizard's name and servant guide you to that which you seek.\"",this.IMAGE_DIR + "wizard.png"),
      new CommonCard(Rank.One, Suit.Stars, "Transmuter","A new discovery; the " +
        "coming of unexpected things; unforeseen consequences & chaos","\"Go to a place of dizzying heights, " +
        "where teh stone itself is alive!\"",this.IMAGE_DIR + "transmuter.png"),
      new CommonCard(Rank.Two, Suit.Stars, "Diviner","The pursuit of knowledge " +
        "tempered by wisdom, truth and honesty; sages and prophecy","\"Look to the one who sees all.  " +
        "The treasure is hidden in her camp.\"",this.IMAGE_DIR + "diviner.png"),
      new CommonCard(Rank.Three, Suit.Stars, "Enchanter","Inner turmoil that comes " +
        "from confusion, fear of failure, or false information","\"I see a kneeling woman - a rose of " +
        "great beauty plucked too soon.  The master of the marsh knows of whom I speak.\"",this.IMAGE_DIR +
        "enchanter.png"),
      new CommonCard(Rank.Four, Suit.Stars, "Abjurer","Those guided by logic and " +
        "reasoning; warns of an overlooked clue or piece of information","\"I see a fallen house guarded by a " +
        "great stone dragon.  Look to the highest peak.\"",this.IMAGE_DIR + "abjurer.png"),
      new CommonCard(Rank.Five, Suit.Stars, "Elementalist","The triumph of nature " +
        "over civilization; natural disasters and bountiful harvests","\"The treasure is hidden in a small " +
        "castle beneath a mountain, guarded by amber giants.\"",this.IMAGE_DIR + "elementalist.png"),
      new CommonCard(Rank.Six, Suit.Stars, "Evoker","Magical or supernatural power " +
        "that can't be controlled; magic for destructive ends","\"Search for the crypt of a wizard ordinaire.  " +
        "His staff is the key.\"",this.IMAGE_DIR + "evoker.png"),
      new CommonCard(Rank.Seven, Suit.Stars, "Illusionist","Lies & deceit; grand " +
        "conspiracies; secret societies; the presence of a dupe or a saboteur","\"A man is not what he seems.  " +
        "He comes here in a carnival wagon.  Therein lies what you seek.\"",this.IMAGE_DIR + "illusionist.png"),
      new CommonCard(Rank.Eight, Suit.Stars, "Necromancer","Unnatural events and " +
        "unhealthy obsessions; those who follow a destructive path","\"A woman hangs above a roaring fire.  " +
        "Find her, and you will find the treasure.\"",this.IMAGE_DIR + "necromancer.png"),
      new CommonCard(Rank.Nine, Suit.Stars, "Conjurer","The coming of an unexpected " +
        "supernatural threat; those who think of themselves as gods","\"I see a dead village, drowned by a " +
        "river, ruled by one who has brought great evil into the world.\"",this.IMAGE_DIR + "conjurer.png"),

      new CommonCard(Rank.Master, Suit.Coins, "Rogue","Anyone for whom money is " +
        "important; those who believe money is the key to their success","\"I see a nest of ravens.  There " +
        "you will find the prize.\"",this.IMAGE_DIR + "rogue.png"),
      new CommonCard(Rank.One, Suit.Coins, "Swashbuckler","Those who like money " +
        "yet give it up freely; likable rogues and rapscallions","\"I see the skeleton of a deadly " +
        "warrior, lying on a bed of stone flanked by gargoyles.\"",this.IMAGE_DIR + "swashbuckler.png"),
      new CommonCard(Rank.Two, Suit.Coins, "Philanthropist","Charity and giving on " +
        "a grand scale; those who use wealth to fight evil and sickness","\"Look to a place where sickness " +
        "and madness are bred.  Where children once cried, the treasure lies still.\"",this.IMAGE_DIR +
        "philanthropist.png"),
      new CommonCard(Rank.Three, Suit.Coins, "Trader","Commerce; smuggling and " +
        "black markets; fair and equitable trades","\"Look to the wizard of wines!  In wood and sand " +
        "the treasure hides.\"",this.IMAGE_DIR + "trader.png"),
      new CommonCard(Rank.Four, Suit.Coins, "Merchant","A rare commodity or business " +
        "opportunity; deceitful or dangerous business transactions","\"Seek a cask that once contained the " +
        "finest wine, of which not a drop remains.\"",this.IMAGE_DIR + "merchant.png"),
      new CommonCard(Rank.Five, Suit.Coins, "Guild Member","Like-minded individuals " +
        "joined together in a common goal; pride in ones work","\"I see a dark room full of bottles.  It " +
        "is a tomb of a guild member.\"",this.IMAGE_DIR + "guild_member.png"),
      new CommonCard(Rank.Six, Suit.Coins, "Beggar","Sudden change in economics " +
        "status or fortune","\"A wounded elf has what you seek.  He will part with the treasure to see his " +
        "dark dreams fulfilled.\"",this.IMAGE_DIR + "beggar.png"),
      new CommonCard(Rank.Seven, Suit.Coins, "Thief","Those who steal or burgle; " +
        "a loss of property, beauty, innocence, friendship or reputation","\"What you seek lies at the " +
        "crossroads of life and death, among the buried dead.\"",this.IMAGE_DIR + "thief.png"),
      new CommonCard(Rank.Eight, Suit.Coins, "Tax Collector","Corruption; honesty " +
        "in an otherwise corrupt government or organization","\"The Vistani have what you seek.  The missing " +
        "child holds the key to the treasure's release.\"",this.IMAGE_DIR + "tax_collector.png"),
      new CommonCard(Rank.Nine, Suit.Coins, "Miser","Hoarded wealth; those who " +
        "are irreversibly unhappy or who think money is meaningless","\"Look for a fortress inside a fortress.  " +
        "In a place hidden behind fire.\"",this.IMAGE_DIR + "miser.png"),

      new CommonCard(Rank.Master, Suit.Glyphs, "Priest","Enlightenment; those who " +
        "follow a deity, a system of values, or a higher purpose","\"You will find what you seek in the " +
        "castle, amid the ruins of a place of supplication.\"",this.IMAGE_DIR + "priest.png"),
      new CommonCard(Rank.One, Suit.Glyphs, "Monk","Serenity; inner strength and " +
        "self-reliance; supreme confidence bereft of arrogance","\"The treasure you seek is hidden behind " +
        "the sun, in the house of a saint.\"",this.IMAGE_DIR + "monk.png"),
      new CommonCard(Rank.Two, Suit.Glyphs, "Missionary","Those who spread wisdom " +
        "and faith to others; warnings of the spread of fear and ignorance","\"I see a garden dusted with " +
        "snow, watched over by a scarecrow with a sackcloth grin.  Look not to the garden but to the guardian\"",
        this.IMAGE_DIR + "missionary.png"),
      new CommonCard(Rank.Three, Suit.Glyphs, "Healer","Healing; a contagious " +
        "illness, disease, or curse; those who practice the healing arts","\"Look to the west.  Find a " +
        "pool blessed by the light of the white sun.\"",this.IMAGE_DIR + "healer.png"),
      new CommonCard(Rank.Four, Suit.Glyphs, "Shepherd","Those who protect others; " +
        "one who bears a burden far too great to be shouldered alone","\"Find the mother - she who gave " +
        "birth to the evil.\"",this.IMAGE_DIR + "shepherd.png"),
      new CommonCard(Rank.Five, Suit.Glyphs, "Druid","The ambivalence and cruelty " +
        "of nature and those who feel drawn to it; inner turmoil","\"An evil tree grows atop a hill of " +
        "graves where the ancient dead sleep.  The ravens can help you find it.  Look for the treasure there.\"",
        this.IMAGE_DIR + "druid.png"),
      new CommonCard(Rank.Six, Suit.Glyphs, "Anarchist","A fundamental change " +
        "brought on by one whose beliefs are being put to the test","\"I see walls of bones, a chandelier " +
        "of bones, and a table of bones - all that remains of enemies long forgotten.\"",
        this.IMAGE_DIR + "anarchist.png"),
      new CommonCard(Rank.Seven, Suit.Glyphs, "Charlatan","Liars; those who " +
        "profess to believe one thing but actually believe another","\"I see a lonely mill on a precipice.  " +
        "The treasure lies within.\"",this.IMAGE_DIR + "charlatan.png"),
      new CommonCard(Rank.Eight, Suit.Glyphs, "Bishop","Strict adherence to a " +
        "code or a belief; those who plot, plan, scheme","\"What you seek lies in a pile of treasure, beyond " +
        "a set of amber doors.\"",this.IMAGE_DIR + "bishop.png"),
      new CommonCard(Rank.Nine, Suit.Glyphs, "Traitor","Betrayal by someone " +
        "close and trusted; a weakening or loss of faith","\"Look for a wealthy woman.  A staunch ally " +
        "of the devil, she keeps the treasure under lock and key, witht he bones of an ancient enemy.\"",
        this.IMAGE_DIR + "traitor.png")
    ];

    this.remainingHighCards = [
      new HighCard("Artifact",
        "The importance of some physical object that must be obtained, protected, or destroyed at all costs",
        "\"Look for an entertaining man with a monkey.  This man is more than he seems.\"",
        "",
        "\"He lurks in the darkness where the morning light once shone - a sacred place\"",
        this.IMAGE_DIR + "artifact.png"),
      new HighCard("Beast",
        "Great rage or passion; something bestial or malevolent hiding in plain sight or lurking just " +
        "below the surface",
        "\"A werewolf holds a secret hatred for your enemy.  Use her hatred to your advantage.\"",
        "",
        "\"The beast sits on his dark throne.\"", this.IMAGE_DIR + "beast.png"),
      new HighCard("Broken One",
        "Default, failure, and despair; the loss of something of someone important, without which one " +
        "feels incomplete",
        "\"Your greatest ally will be a wizard.  his mind is broken, but his spells are strong.\"",
        "\"I see a man of faith whose sanity hangs by a thread.  he has lost someone close to him.\"",
        "\"He haunts the tomb of the one he envied above all.\"",
        this.IMAGE_DIR + "broken_one.png"),
      new HighCard("Darklord","A single, powerful individual of an evil nature, one whose goals " +
        "have enormous and far-reaching consequences",
        "\"Ah, the worst of all truths.  you must face the evil of this land alone!\"",
        "",
        "\"He lurks in the depths of darkness, in the one place he must return.\"",
        this.IMAGE_DIR + "darklord.png"),
      new HighCard("Donjon","Isolation and imprisonment; being so conservative in thinking as to " +
        "be a prisoner of one's own beliefs",
        "\"Search for a troubled young man surrounded by wealth and madness.  His home is his prison.\"",
        "\"Find a girl driven to insanity, locked in the heart of her dead father's house.  Curing her " +
        "madness is the key to your success.\"",
        "\"He lurks in a hall of bones, in the dark pits of his castle.\"",
        this.IMAGE_DIR + "donjon.png"),
      new HighCard("Ghost",
        "The looming past; the return of an old enemy or the discovery of a secret buried long ago",
        "\"I see a fallen paladin of a fallen order of knights.  he lingers like a ghost in a dead " +
        "dragon's lair.\"",
        "\"Stir the spirit of the clumsy knight whose crypt lies deep within the castle.\"",
        "\"Look to the father's tomb.\"",
        this.IMAGE_DIR + "ghost.png"),
      new HighCard("Executioner","The imminent death of " +
        "one rightly or wrongly convicted of a crime; false accusations and unjust prosecution",
        "\"Seek out the brother of the devil's bride.  They call him \"The lesser,\" but he has a powerful soul.\"",
        "",
        "\"I see a dark figure on a balcony, looking down on this tortured land with a twisted smile.\"",this.IMAGE_DIR + "executioner.png"),
      new HighCard("Horseman","Death; disaster in the form of wealth or property, a horrible defeat" +
        ", or the end of a bloodline",
        "\"I see a dead man of noble birth, guarded by his widow.  Return life to the dead man's corpse, " +
        "and he will be your staunch ally.\"",
        "\"A man of death named Arrigal will forsake his dark lord to serve your cause.  Beware!  He " +
        "has a rotten soul.\"",
        "\"He lurks in the one place to which he must return - a place of death.\"",this.IMAGE_DIR + "horseman.png"),
      new HighCard("Innocent","A being of great importance whose life is in danger (who might be " +
        "helpless or simply unaware of the peril)",
        "\"I see a young man with a kind heart.  A mother's boy!  He is strong in body but weak of " +
        "mind.  Seek him out in the village of Barovia.\"",
        "\"Evil's bride is the one you seek!\"",
        "\"He dwells with the one whose blood sealed his doom, a brother of light snuffed out too soon.\"",this.IMAGE_DIR + "innocent.png"),
      new HighCard("Marionette","The presence of a spy " +
        "or a minion of some greater power; an encounter with a puppet or an underling",
        "\"What horror is this?  I see a man made by a man.  Ageless and alone, it haunts the towers of the castle.\"",
        "\"Look for a man of music, a man with two heads.  He lives in a place of great hunger and sorrow.\"",
        "\"Look to great heights.  Find the beating heart of the castle.  He waits nearby.\"",this.IMAGE_DIR + "marionette.png"),
      new HighCard("Mists","Something unexpected or " +
        "mysterious that can't be avoided; a great quest or journey that will try one's spirit",
        "\"A vistana wanders this land alone, searching for her mentor.  She does not stay in one place " +
        "for long.  Seek her out at Saint Markovia's abbey, near the mists.\"",
        "",
        "\"The cards can't see where the evil lurks.  The mists obscure all!\"",this.IMAGE_DIR + "mists.png"),
      new HighCard("Raven","A hidden source of " +
        "information; a fortunate turn of events; a secret potential for good",
        "\"Find the leader of the feathered ones who live among the vines.  Though old, he has one " +
        "more fight left in him.\"",
        "",
        "\"Look to the mother's tomb.\"",this.IMAGE_DIR + "raven.png"),
      new HighCard("Seer","Inspiration and keen " +
        "intellect; a future event, the outcome of which will hinge on a clever mind",
        "\"Look for a disk elf living among the Vistani.  He has suffered a great loss and is haunted " +
        "by dark dreams.  Help him, and he will help you in return.\"",
        "",
        "\"He waits for you in a place of wisdom, warmth, and despair.  Great secrets are there.\"",this.IMAGE_DIR + "seer.png"),
      new HighCard("Tempter","One who has been " +
        "compromised or led astray by temptation or foolishness; one who tempts others for evil ends",
        "\"I see a child - a Vistani.  You must hurry, for her fate hangs in the balance. " +
        "Find her at the lake!\"",
        "\"I hear a wedding bell, or perhaps a death knell.  It calls thee to a mountainside abbey, " +
        "wherein you will find a woman who is more than the sum of her parts.\"",
        "\"I see a secret place - a vault of temptation hidden behind a woman of great beauty.  " +
        "The evil waits atop his tower of treasure.\"",this.IMAGE_DIR + "tempter.png")
    ];
    this.position = [];
    this.positionSubject.next(this.position);
  }

  revealPos(pos: number){
    var revealFlag:boolean = true;

    for(var i=0; i < this.position.length; i++){
      if(i < pos){
        if(this.position[i] && this.position[i].state != CardState.Revealed){
          revealFlag = false;
        }
      } else if (i == pos){
        if(this.position[i] && this.position[i].state == CardState.Unrevealed){
          revealFlag = true;
        }
      }

      if (revealFlag) {
        this.position[i].advanceState();
        this.positionSubject.next(this.position);
      }
    }
  }

  advanceState(pos: number){
    this.position[pos].advanceState();
    this.positionSubject.next(this.position);
    this.selectedCard.next(this.position[pos]);
  }

  dealAll5(){
    this.resetDeck();
    for(var i=0; i < 5; i++){
      this.drawPosition(i);
    }
  }

  drawPosition(pos: number){
    if (pos == 0 || pos == 1 || pos == 2){
      this.position[pos] = this.drawCommonCard();
      this.position[pos].position = pos;
      this.positionSubject.next(this.position);
      this.selectedCard.next(this.position[pos]);
    } else if (pos == 3 || pos == 4){
      this.position[pos] = this.drawHighCard();
      this.position[pos].position = pos;
      this.positionSubject.next(this.position);
      this.selectedCard.next(this.position[pos]);
    }
  }

  private drawCommonCard(): Card {
    var randomInt = this.getRandomInt(this.remainingCommonCards.length);
    return this.remainingCommonCards.splice(randomInt, 1)[0];
  }

  private drawHighCard(): Card {
    var randomInt = this.getRandomInt(this.remainingHighCards.length);
    return this.remainingHighCards.splice(randomInt, 1)[0];
  }

  private getRandomInt(max: number): number {
    return Math.floor(Math.random() * Math.floor(max));
  }

}
