import {Component} from '@angular/core';
import {DeckService} from '../deck/deck.service';
import {CardState} from '../card/card/card-state.model';
import {Card, CommonCard, HighCard} from '../card/card/card.model';

@Component({
  selector: 'app-tarokka',
  templateUrl: './tarokka.component.html',
  styleUrls: ['./tarokka.component.scss']
})
export class TarokkaComponent{

  positionDesc = "";
  cardTitle = "";
  cardDescription = "";
  cardClue = "";
  dealButtonText = "Deal";
  summaryButtonText = "Show Summary";
  summaryUrl = "assets/icons/card-text.svg";
  dealDisabled = false;
  hideRedealAndSummary = false;

  POSITION_1_DESC = "This card tells of history.  Knowledge of the ancients will help you better understand your enemy.";
  POSITION_2_DESC = "This card tells you of a powerful force for good and protection, a holy symbol of great hope.";
  POSITION_3_DESC = "This is a card of power and strength.  It tells of a weapon of vengeance: a sword of sunlight.";
  POSITION_4_DESC = "This card sheds light on the one who will help you greatly in the battle against darkness.";
  POSITION_5_DESC = "Your enemy is a creature of darkness, whose powers are beyond mortality.  This card will lead you to him!";

  position1Title = "";
  position2Title = "";
  position3Title = "";
  position4Title = "";
  position5Title = "";

  position1Desc = "";
  position2Desc = "";
  position3Desc = "";
  position4Desc = "";
  position5Desc = "";

  position1Clue = "";
  position2Clue = "";
  position3Clue = "";
  position4Clue = "";
  position5Clue = "";

  showSummary = false;

  cards: Card[] = [];

  constructor(public deckService:DeckService) {
    this.deckService.positionSubject.subscribe((pos) => {
      this.cards = pos;
      this.hideRedealAndSummary = true;

      if(this.allUndrawnCheck()){
        this.dealButtonText = "Draw Card";
        this.dealDisabled = false;
      } else if (this.allUnrevealedCheck()){
        this.dealButtonText = "Flip Card"
        this.dealDisabled = false;
      } else if (this.allRevealedCheck()) {
        this.dealButtonText = "Draw Card"
        this.dealDisabled = true;
      }
    });

    this.deckService.selectedCard.subscribe((selectedCard) => {
      this.setPositionDesc(selectedCard);
      this.setTitleDescClue(selectedCard);
    });
  }



  deal5() {
    this.deckService.dealAll5();
  }

  dealCard(){
    if(this.cards.length == 0){
      this.deal5();
    } else {
      var card1State = this.cards[0].state;

      if(this.allRevealedCheck()){
        // do nothing
      } else if(this.allUndrawnCheck() || this.allUnrevealedCheck()){
        this.deckService.advanceState(0);
      } else if(card1State == CardState.Unrevealed){
        var undrawnPos = -1;
        for(var i=1; i<5; i++ ){
          if (this.cards[i].state == CardState.Undrawn){
            undrawnPos = i;
            break;
          }
        }
        if (undrawnPos > 0){
          this.deckService.advanceState(undrawnPos);
        } else {
          console.log("All 5 are Drawn")
        }
      } else if(card1State == CardState.Revealed){
        var unrevealedPos = -1;
        for(var i=1; i<5; i++ ){
          if (this.cards[i].state == CardState.Unrevealed){
            unrevealedPos = i;
            break;
          }
        }
        if (unrevealedPos > 0){
          this.deckService.advanceState(unrevealedPos);
        } else {
          //this.deal5();
        }
      }
    }
  }


  selectPositionN(pos: number){
    if(this.allUnrevealedCheck() && pos == 0) {
      this.deckService.advanceState(0);
    } else if (this.cards[pos].state == CardState.Unrevealed
      && this.cards[pos-1].state == CardState.Revealed) {
      this.deckService.advanceState(pos);
    } else {
      this.setPositionDesc(this.cards[pos]);
      this.setTitleDescClue(this.cards[pos]);
    }
  }

  private allUndrawnCheck(){
    for(var i=0; i < this.cards.length; i++){
      if (this.cards[i].state != CardState.Undrawn) return false;
    }
    return true;
  }

  private allUnrevealedCheck(){
    for(var i=0; i < this.cards.length; i++){
      if (this.cards[i].state != CardState.Unrevealed) return false;
    }
    return true;
  }

  private allRevealedCheck(){
    for(var i=0; i < this.cards.length; i++){
      if (this.cards[i].state != CardState.Revealed) return false;
    }
    return true;
  }

  private setTitleDescClue(card: Card){
    console.log(card);


    if(card.state == CardState.Revealed ) {
      if (card instanceof CommonCard) {
        this.cardTitle = (<CommonCard> card).getTitle();
        this.cardDescription = card.getDescription();
        this.cardClue = (<CommonCard> card).getClue();
      } else if (card instanceof HighCard) {
        this.cardTitle = (<HighCard> card).getName();
        this.cardDescription = card.getDescription();
        if (card.position == 4) {
          this.cardClue = (<HighCard> card).getEnemyClue();
        } else {
          this.cardClue = (<HighCard> card).getLocationClue();
        }
      }
    } else {
      // if(this.allUndrawnCheck()){
      //   this.positionDesc = "";
      // } else {
      //   switch (card.position) {
      //     case 0:
      //       this.positionDesc = this.POSITION_1_DESC;
      //       break;
      //     case 1:
      //       this.positionDesc = this.POSITION_2_DESC;
      //       break;
      //     case 2:
      //       this.positionDesc = this.POSITION_3_DESC;
      //       break;
      //     case 3:
      //       this.positionDesc = this.POSITION_4_DESC;
      //       break;
      //     case 4:
      //       this.positionDesc = this.POSITION_5_DESC;
      //       break;
      //     default:
      //       break;
      //
      //       this.cardTitle = "";
      //       this.cardDescription = "";
      //       this.cardClue = "";
      //   }
      // }
      this.cardTitle = "";
      this.cardDescription = "";
      this.cardClue = "";

      this.position1Title = "";
      this.position2Title = "";
      this.position3Title = "";
      this.position4Title = "";
      this.position5Title = "";

      this.position1Desc = "";
      this.position2Desc = "";
      this.position3Desc = "";
      this.position4Desc = "";
      this.position5Desc = "";

      this.position1Clue = "";
      this.position2Clue = "";
      this.position3Clue = "";
      this.position4Clue = "";
      this.position5Clue = "";
    }

    if(card.state == CardState.Revealed ) {
      if (card.position == 0) {
        this.position1Title = this.cardTitle;
        this.position1Desc = this.cardDescription;
        this.position1Clue = this.cardClue;
      } else if (card.position == 1) {
        this.position2Title = this.cardTitle;
        this.position2Desc = this.cardDescription;
        this.position2Clue = this.cardClue;
      } else if (card.position == 2) {
        this.position3Title = this.cardTitle;
        this.position3Desc = this.cardDescription;
        this.position3Clue = this.cardClue;
      } else if (card.position == 3) {
        this.position4Title = this.cardTitle;
        this.position4Desc = this.cardDescription;
        this.position4Clue = this.cardClue;
      } else if (card.position == 4) {
        this.position5Title = this.cardTitle;
        this.position5Desc = this.cardDescription;
        this.position5Clue = this.cardClue;
      }
    }
  }

  setPositionDesc(card: Card){
    if(this.allUndrawnCheck()){
      this.positionDesc = "";
    } else {
      switch (card.position) {
        case 0:
          this.positionDesc = this.POSITION_1_DESC;
          break;
        case 1:
          this.positionDesc = this.POSITION_2_DESC;
          break;
        case 2:
          this.positionDesc = this.POSITION_3_DESC;
          break;
        case 3:
          this.positionDesc = this.POSITION_4_DESC;
          break;
        case 4:
          this.positionDesc = this.POSITION_5_DESC;
          break;
        default:
          break;

          this.cardTitle = "";
          this.cardDescription = "";
          this.cardClue = "";
      }
    }
  }

  toggleSummary(){
    this.showSummary = !this.showSummary;

    if(this.showSummary) {
      this.summaryButtonText = "Show Layout";
      this.summaryUrl = "assets/icons/grid-3x3-gap.svg";
    } else {
      this.summaryButtonText = "Show Summary";
      this.summaryUrl = "assets/icons/card-text.svg";
    }
  }




}
