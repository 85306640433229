export class MaddenPlayerModel{
  constructor(public NAME: string,
              public TEAM: string,
              public POS: string,
              public OVR: number,
              public SPD: number,
              public ACC: number,
              public STR: number,
              public AWR: number,
              public THP: number,
              public CTH: number,
              public CIT: number,
              public SPC: number,
              public PAC: number,
              public PUR: number,
              public BCV: number,
              public SPN: number,
              public PWR: number,
              public FMV: number,
              public TAM: number,
              public RBK: number,
              public INJ: number,
              public TKL: number,
              public TRK: number,
              public TAS: number,
              public JUK: number,
              public PRC: number,
              public SRR: number,
              public MRR: number,
              public DRR: number,
              public JMP: number,
              public TGH: number,
              public TOR: number,
              public MCV: number,
              public ZCV: number,
              public STF: number,
              public REL: number,
              public HP: number,
              public KPW: number,
              public KAC: number,
              public BRS: number,
              public IBL: number,
              public STA: number,
              public CAR: number,
              public BTK: number,
              public TUP: number,
              public PBK: number,
              public PRS: number,
              public TAD: number,
              public BKS: number,
              public AGI: number,
              public ELU: number,
              public KR: number){

  }
}
