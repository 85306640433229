import { Component, OnInit } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Color } from 'ng2-charts';


@Component({
  selector: 'app-bubble-chart',
  templateUrl: './bubble-chart.component.html',
  styleUrls: ['./bubble-chart.component.scss']
})
export class BubbleChartComponent {

  public bubbleChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [{
        ticks: {
          min: 10,
          max: 30,
        }
      }],
      yAxes: [{
        ticks: {
          min: 150,
          max: 650,
        }
      }]
    }
  };
  public bubbleChartType: ChartType = 'bubble';
  public bubbleChartLegend = true;

  public bubbleChartData: ChartDataSets[] = [
    {
      data: this.getRandomSet(80),
      label: 'Investment Equities'
    },
  ];

  private getRandomX(levelOfCorrelation: number, percentile:number){
    const variance = levelOfCorrelation * .01;
    const weightedPercentile = (variance/2) + (percentile*variance);
    //between 11 and 29
    return Math.floor(Math.random() * 18.0) + 11;
  }

  private getRandomY(levelOfCorrelation: number, percentile:number){
    const variance = levelOfCorrelation * .01;
    const weightedPercentile = (variance/2) + (percentile*variance);
    //between 160 and 640
    return Math.floor(Math.random() * 480.0) + 160;

  }

  private getRandomR(){
    //between 10 and 50
    return Math.floor(Math.random() * 40.0) + 10;

  }

  getRandomSet(levelOfCorrelation: number) {
    const returnSet = [];

    for(let i=0; i < 20; i++){
      const randomNumber = Math.random();
      returnSet.push({
        x: this.getRandomX(levelOfCorrelation, randomNumber),
        y: this.getRandomY(levelOfCorrelation, randomNumber),
        r: this.getRandomR()
      });
    }

    return returnSet;
  }

  generateNewData(){
    this.bubbleChartData = [{
        data: this.getRandomSet(20),
        label: 'Investment Equities'
      },
    ];
  }


}
