import {CardState} from './card-state.model';
import {Suit} from './suit.model';
import {Rank} from './rank.model';

export class Card {
  state:CardState;
  position: number;

  constructor(private name: string, private description: string, public imgUrl: string) {
    this.state = CardState.Undrawn;
    this.position = -1;
  }

  getName(){
    return this.name;
  }

  getDescription(){
    return this.description;
  }

  getImgUrl(){
    return this.imgUrl;
  }

  setImgUrl(url: string){
    this.imgUrl = url;
  }

  advanceState(){
    if (this.state == CardState.Undrawn){
      this.state = CardState.Unrevealed;
    } else if (this.state == CardState.Unrevealed){
      this.state = CardState.Revealed;
    } else if (this.state == CardState.Revealed){
      this.state = CardState.Undrawn;
    }
  }
}

export class CommonCard extends Card {
  constructor(private rank: Rank, private suit: Suit, name: string, description: string, private clue:string, imgUrl: string) {
    super(name, description, imgUrl);
  }

  getTitle(){
    if(this.rank != null) {
      return Rank[this.rank] + " of " + Suit[this.suit] + " (The " + super.getName() + ")";
    } else {
      return super.getName();
    }
  }

  getRank(){
    return this.rank;
  }

  getSuit(){
    return this.suit;
  }

  getClue(){
    return this.clue;
  }
}

export class HighCard extends Card {
  constructor(name: string, description: string, private enemyClueA: string, private enemyClueB: string, private locationClue: string, imgUrl: string) {
    super(name, description, imgUrl);
  }

  getEnemyClue(){
    if(this.enemyClueB) {
      var number = Math.random();
      if (number >= 0.5) {
        return this.enemyClueA;
      } else return this.enemyClueB;
    } else return this.enemyClueA;
  }

  getLocationClue(){
    return this.locationClue;
  }
}

