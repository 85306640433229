export class ItemModel {
  constructor(
    public id: string,
    public title: string,
    public description: string,
    public imgUrl: string,
    public manufacturer: string,
    public price: number,
    public category: string,
    public inventoryStatus: string,
    public rating?: number,
    public saleMarkoff?: number){
  }
}
