import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header/header.component';
import { AlertComponent } from './alert/alert/alert.component';
import { TarokkaComponent } from './tarokka/tarokka/tarokka.component';
import { CardComponent } from './tarokka/card/card/card.component';

import {DeckService} from './tarokka/deck/deck.service';
import {InfoService} from './info/info.service';

import {DialogModule} from 'primeng/dialog';
import {ButtonModule} from 'primeng/button';
import {TooltipModule} from 'primeng/tooltip';
import {SplitButtonModule} from 'primeng/splitbutton';
import {SidebarModule} from 'primeng/sidebar';
import {DropdownModule} from 'primeng/dropdown';
import {RouterModule} from '@angular/router';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {StepsModule} from 'primeng/steps';
import {MenuModule} from 'primeng/menu';
import {ToolbarModule} from 'primeng/toolbar';
import {FormsModule} from '@angular/forms';
import {AngularCesiumModule, AngularCesiumWidgetsModule, CameraService, MapEventsManagerService} from 'angular-cesium';
import { CesiumViewComponent } from './cesium/cesium-view/cesium-view.component';
import { PlaneLayerComponent } from './cesium/cesium-view/plane/plane-layer/plane-layer.component';
import { PolylineLayerComponent } from './cesium/cesium-view/polyline/polyline-layer/polyline-layer.component';
import { CylinderLayerComponent } from './cesium/cesium-view/cylinder/cylinder-layer/cylinder-layer.component';
import {HttpClientModule} from '@angular/common/http';
import { CesiumDirective } from './cesium.directive';
import {ChartsModule} from 'ng2-charts';
import { ChartViewComponent } from './chart/chart-view/chart-view/chart-view.component';
import { LineChartComponent } from './chart/chart-view/line-chart/line-chart.component';
import { BarChartComponent } from './chart/chart-view/bar-chart/bar-chart.component';
import { DoughnutChartComponent } from './chart/chart-view/doughnut-chart/doughnut-chart.component';
import { RadarChartComponent } from './chart/chart-view/radar-chart/radar-chart.component';
import { PieChartComponent } from './chart/chart-view/pie-chart/pie-chart.component';
import { BubbleChartComponent } from './chart/chart-view/bubble-chart/bubble-chart.component';
import {SliderModule} from 'primeng/slider';
import {MultiSelectModule} from 'primeng/multiselect';
import {TableModule} from 'primeng/table';
import { Resume2Component } from './resume/resume2/resume2.component';
import {AccordionModule} from 'primeng/accordion';
import {ToastModule} from 'primeng/toast';
import {MessageService} from 'primeng/api';
import { ShoppingCartComponent } from './shopping-cart/shopping-cart.component';
import {MegaMenuModule} from 'primeng/megamenu';
import {DataViewModule} from 'primeng/dataview';
import {TimelineModule} from 'primeng/timeline';
import {OrderListModule} from 'primeng/orderlist';
import {RippleModule} from 'primeng/ripple';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import { ItemDisplayComponent } from './shopping-cart/item-display/item-display.component';
import { ItemDetailComponent } from './shopping-cart/item-detail/item-detail.component';
import {RatingModule} from 'primeng/rating';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    AlertComponent,
    TarokkaComponent,
    CardComponent,
    CesiumViewComponent,
    PlaneLayerComponent,
    PolylineLayerComponent,
    CylinderLayerComponent,
    CesiumDirective,
    ChartViewComponent,
    LineChartComponent,
    BarChartComponent,
    DoughnutChartComponent,
    RadarChartComponent,
    PieChartComponent,
    BubbleChartComponent,
    Resume2Component,
    ShoppingCartComponent,
    ItemDisplayComponent,
    ItemDetailComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,
    ButtonModule,
    DialogModule,
    TooltipModule,
    ToolbarModule,
    SplitButtonModule,
    SidebarModule,
    DropdownModule,
    ScrollPanelModule,
    StepsModule,
    MenuModule,
    HttpClientModule,
    AngularCesiumModule.forRoot(),
    AngularCesiumWidgetsModule,
    ChartsModule,
    SliderModule,
    MultiSelectModule,
    TableModule,
    AccordionModule,
    ToastModule,
    MegaMenuModule,
    DataViewModule,
    TimelineModule,
    OrderListModule,
    RippleModule,
    OverlayPanelModule,
    RatingModule

  ],
  exports: [RouterModule],
  providers: [InfoService, DeckService, CameraService, MessageService],
  bootstrap: [AppComponent]
})
export class AppModule { }
