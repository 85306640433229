import {MaddenPlayerModel} from '../models/madden-player.model';
import {Color} from 'ng2-charts';
import {ItemModel} from '../shopping-cart/item.model';

export class ArrayUtil {
  public static states = [{
      "name": "Alabama",
      "abbreviation": "AL"
    },{
      "name": "Alaska",
      "abbreviation": "AK"
    },{
      "name": "American Samoa",
      "abbreviation": "AS"
    },{
      "name": "Arizona",
      "abbreviation": "AZ"
    },{
      "name": "Arkansas",
      "abbreviation": "AR"
    },{
      "name": "California",
      "abbreviation": "CA"
    },{
      "name": "Colorado",
      "abbreviation": "CO"
    },{
      "name": "Connecticut",
      "abbreviation": "CT"
    },{
      "name": "Delaware",
      "abbreviation": "DE"
    },{
      "name": "District Of Columbia",
      "abbreviation": "DC"
    },{
      "name": "Federated States Of Micronesia",
      "abbreviation": "FM"
    },{
      "name": "Florida",
      "abbreviation": "FL"
    },{
      "name": "Georgia",
      "abbreviation": "GA"
    },{
      "name": "Guam",
      "abbreviation": "GU"
    },{
      "name": "Hawaii",
      "abbreviation": "HI"
    },{
      "name": "Idaho",
      "abbreviation": "ID"
    },{
      "name": "Illinois",
      "abbreviation": "IL"
    },{
      "name": "Indiana",
      "abbreviation": "IN"
    },{
      "name": "Iowa",
      "abbreviation": "IA"
    },{
      "name": "Kansas",
      "abbreviation": "KS"
    },{
      "name": "Kentucky",
      "abbreviation": "KY"
    },{
      "name": "Louisiana",
      "abbreviation": "LA"
    },{
      "name": "Maine",
      "abbreviation": "ME"
    },{
      "name": "Marshall Islands",
      "abbreviation": "MH"
    },{
      "name": "Maryland",
      "abbreviation": "MD"
    },{
      "name": "Massachusetts",
      "abbreviation": "MA"
    },{
      "name": "Michigan",
      "abbreviation": "MI"
    },{
      "name": "Minnesota",
      "abbreviation": "MN"
    },{
      "name": "Mississippi",
      "abbreviation": "MS"
    },{
      "name": "Missouri",
      "abbreviation": "MO"
    },{
      "name": "Montana",
      "abbreviation": "MT"
    },{
      "name": "Nebraska",
      "abbreviation": "NE"
    },{
      "name": "Nevada",
      "abbreviation": "NV"
    },{
      "name": "New Hampshire",
      "abbreviation": "NH"
    },{
      "name": "New Jersey",
      "abbreviation": "NJ"
    },{
      "name": "New Mexico",
      "abbreviation": "NM"
    },{
      "name": "New York",
      "abbreviation": "NY"
    },{
      "name": "North Carolina",
      "abbreviation": "NC"
    },{
      "name": "North Dakota",
      "abbreviation": "ND"
    },{
      "name": "Northern Mariana Islands",
      "abbreviation": "MP"
    },{
      "name": "Ohio",
      "abbreviation": "OH"
    },{
      "name": "Oklahoma",
      "abbreviation": "OK"
    },{
      "name": "Oregon",
      "abbreviation": "OR"
    },{
      "name": "Palau",
      "abbreviation": "PW"
    },{
      "name": "Pennsylvania",
      "abbreviation": "PA"
    },{
      "name": "Puerto Rico",
      "abbreviation": "PR"
    },{
      "name": "Rhode Island",
      "abbreviation": "RI"
    },{
      "name": "South Carolina",
      "abbreviation": "SC"
    },{
      "name": "South Dakota",
      "abbreviation": "SD"
    },{
      "name": "Tennessee",
      "abbreviation": "TN"
    },{
      "name": "Texas",
      "abbreviation": "TX"
    },{
      "name": "Utah",
      "abbreviation": "UT"
    },{
      "name": "Vermont",
      "abbreviation": "VT"
    },{
      "name": "Virgin Islands",
      "abbreviation": "VI"
    },{
      "name": "Virginia",
      "abbreviation": "VA"
    },{
      "name": "Washington",
      "abbreviation": "WA"
    },{
      "name": "West Virginia",
      "abbreviation": "WV"
    },{
      "name": "Wisconsin",
      "abbreviation": "WI"
    },{
      "name": "Wyoming",
      "abbreviation": "WY"
    }
  ];

  public static maddenPlayers = [
    new MaddenPlayerModel("Patrick Mahomes","KC","QB",97,81,87,69,92,96,37,32,30,87,37,50,30,10,10,30,20,88,34,41,35,51,25,10,10,10,87,79,29,13,22,37,15,58,88,61,24,22,89,50,34,13,10,10,22,24,79,43,10),
    new MaddenPlayerModel("Travis Kelce","KC","TE",96,85,88,79,99,64,93,92,92,29,42,61,60,36,40,5,35,92,70,47,5,62,75,25,15,11,90,90,5,64,69,51,20,81,12,10,17,60,90,57,47,14,35,71,5,41,81,62,50),
    new MaddenPlayerModel("Tyreek Hill","KC","WR",94,99,99,64,85,36,90,90,88,12,33,53,57,43,39,6,19,91,75,57,6,63,69,20,10,10,92,92,6,62,66,42,23,83,21,18,15,63,89,62,45,10,25,67,6,49,86,60,42),
    new MaddenPlayerModel("Mitchell Schwartz","KC","RT",93,59,75,88,94,11,27,15,15,6,30,54,68,32,36,6,35,87,46,25,6,65,69,20,10,10,87,68,6,68,64,32,15,40,10,11,10,47,92,62,50,16,35,72,6,52,93,60,60),
    new MaddenPlayerModel("Chris Jones","KC","DT",89,71,85,91,95,23,29,14,16,6,89,66,68,10,10,22,61,89,27,68,26,68,15,67,63,60,83,92,18,15,25,64,69,12,17,18,18,63,86,75,48,10,55,10,19,33,84,48,10),
    new MaddenPlayerModel("Kendall Fuller","KC","CB",87,90,92,67,86,27,68,58,61,6,80,45,25,62,78,6,45,94,74,40,6,35,67,10,5,5,80,90,6,43,47,55,12,83,30,30,12,67,77,50,30,10,45,30,6,64,75,45,6),
    new MaddenPlayerModel("Tyrann Mathieu","KC","SS",87,90,92,61,86,35,81,55,66,19,82,25,17,71,61,6,45,87,79,37,6,24,72,16,8,4,69,81,6,20,29,28,15,83,20,17,12,86,76,25,15,10,45,16,6,70,57,20,10),
    new MaddenPlayerModel("Frank Clark","KC","RE",83,84,90,88,90,27,37,21,30,6,84,51,42,56,74,6,45,91,76,38,6,49,52,25,20,15,85,95,6,48,57,29,20,80,20,16,18,81,86,60,34,17,45,53,6,65,84,40,10),
    new MaddenPlayerModel("Sammy Watkins","KC","WR",82,91,91,67,88,40,87,83,87,10,35,10,10,74,52,6,45,88,80,21,6,10,70,15,7,3,75,75,6,20,25,19,15,74,10,15,12,78,73,41,10,10,45,13,6,61,70,11,10),
    new MaddenPlayerModel("Dustin Colquitt","KC","P",81,68,79,40,70,40,23,15,15,20,32,68,66,22,34,6,35,90,60,32,6,80,72,54,44,37,85,78,6,72,67,42,52,58,21,15,12,57,87,63,52,17,35,68,6,41,88,62,65),
    new MaddenPlayerModel("Damien Williams","KC","HB",80,90,91,67,83,28,70,74,73,15,46,78,74,10,10,22,42,86,38,83,27,82,18,61,56,44,88,88,28,13,18,78,57,24,16,12,27,48,87,90,76,16,56,12,17,27,78,69,76),
    new MaddenPlayerModel("Harrison Butker","KC","K",79,75,82,44,69,22,30,10,12,20,12,84,81,10,10,47,45,88,31,64,56,88,15,71,72,65,81,91,67,10,22,65,68,12,25,25,27,42,93,72,82,28,36,10,40,45,88,84,88),
    new MaddenPlayerModel("Laurent Duvernay-Tardif","KC","RG",78,72,76,92,81,17,40,30,25,6,31,65,57,10,10,17,71,87,35,66,19,63,18,61,57,51,80,90,16,15,20,62,61,15,19,13,15,70,83,69,60,15,63,15,14,35,77,48,15),
    new MaddenPlayerModel("Anthony Hitchens","KC","ROLB",77,82,87,77,79,25,61,45,47,6,83,45,44,54,64,6,45,87,81,44,6,54,65,15,5,5,83,87,6,61,55,40,15,83,18,14,12,82,86,51,24,11,45,45,6,66,79,39,25),
    new MaddenPlayerModel("Anthony Sherman","KC","FB",77,78,85,83,83,35,67,73,68,10,70,83,82,10,10,34,43,86,27,39,39,88,18,79,75,74,84,76,38,16,23,45,67,17,16,11,38,41,89,70,74,25,34,13,26,23,89,81,85),
    new MaddenPlayerModel("Carlos Hyde","KC","HB",76,87,90,80,75,35,67,63,54,15,39,15,22,27,22,6,71,93,36,29,6,20,23,10,5,5,70,86,6,12,15,15,20,28,19,14,12,79,84,25,11,10,73,10,6,34,63,21,10),
    new MaddenPlayerModel("Derrick Nnadi","KC","DT",76,63,79,87,76,24,25,15,15,6,81,80,71,19,31,49,49,90,47,54,53,76,35,71,72,73,88,78,54,36,39,58,69,52,19,14,29,47,87,69,71,37,32,31,45,46,88,67,75),
    new MaddenPlayerModel("Emmanuel Ogbah","KC","LE",76,85,88,79,80,22,38,17,17,6,82,65,74,10,10,10,51,90,36,54,15,76,15,72,70,66,93,72,10,45,52,79,77,25,25,25,22,45,83,70,69,19,21,35,6,49,83,65,58),
    new MaddenPlayerModel("Alex Okafor","KC","LE",76,74,83,79,79,14,49,25,25,6,83,82,76,10,10,40,43,89,29,37,48,83,16,71,70,71,84,82,49,17,20,66,67,14,52,52,27,38,90,72,74,36,28,14,42,26,92,80,81),
    new MaddenPlayerModel("Bashaud Breeland","KC","CB",74,87,90,57,76,20,73,44,61,6,68,21,10,10,10,15,20,91,35,15,15,10,10,10,10,10,55,83,18,21,29,15,12,12,96,66,19,23,87,25,20,14,20,10,15,26,63,20,10),
    new MaddenPlayerModel("Eric Fisher","KC","LT",74,69,82,88,83,29,40,30,25,6,35,52,65,49,55,6,35,88,68,35,6,75,64,10,10,10,88,65,6,66,71,25,15,70,20,20,13,64,85,55,49,14,35,60,6,55,89,64,43),
    new MaddenPlayerModel("Demarcus Robinson","KC","WR",74,88,91,60,79,24,80,78,83,14,34,38,18,77,67,6,45,86,78,36,6,26,69,16,8,4,63,78,6,32,44,42,13,83,15,15,12,87,81,45,16,14,45,23,6,62,67,31,10),
    new MaddenPlayerModel("Darron Lee","KC","MLB",73,87,88,72,81,62,58,44,50,39,83,22,35,10,10,33,24,85,33,25,37,30,12,10,10,10,49,65,8,12,12,10,12,12,92,74,25,10,88,40,20,13,16,10,27,15,64,35,10),
    new MaddenPlayerModel("Reggie Ragland","KC","MLB",73,82,87,79,76,25,56,27,30,5,84,63,63,32,40,6,35,93,67,36,6,74,68,25,15,10,84,86,6,71,67,37,20,75,10,11,17,58,92,63,56,10,35,82,6,54,87,65,76),
    new MaddenPlayerModel("Breeland Speaks","KC","RE",72,79,87,83,66,20,38,28,25,6,83,80,76,14,10,27,41,93,37,82,34,81,24,60,53,40,73,89,27,14,21,79,52,32,20,22,28,44,92,87,81,18,57,19,19,34,82,75,75),
    new MaddenPlayerModel("Xavier Williams","KC","DT",71,62,77,86,77,29,30,10,12,6,79,58,32,58,53,6,45,93,83,39,6,43,65,24,14,6,75,96,6,53,54,46,18,77,14,22,12,79,90,61,29,10,45,45,6,69,79,44,10),
    new MaddenPlayerModel("Jeremiah Attaochu","KC","ROLB",70,85,87,73,67,27,47,35,40,6,80,15,10,20,22,6,67,88,40,33,6,10,20,12,5,5,74,88,6,13,15,10,12,25,20,24,12,84,90,39,7,15,75,10,6,40,70,15,10),
    new MaddenPlayerModel("Mecole Hardman","KC","WR",70,95,92,60,68,60,80,76,80,36,51,73,70,37,32,10,46,87,53,44,15,76,39,74,70,73,84,85,10,42,47,48,68,44,11,11,22,48,89,73,72,16,37,36,6,52,85,69,62),
    new MaddenPlayerModel("Khalen Saunders","KC","DT",70,73,83,84,71,25,46,39,39,6,80,71,66,10,10,15,50,82,35,51,20,78,22,70,69,72,78,86,15,20,26,52,72,27,19,15,22,50,85,66,73,19,43,17,15,28,87,67,48),
    new MaddenPlayerModel("Daniel Sorensen","KC","SS",70,84,88,65,76,30,62,44,64,10,63,70,67,30,45,10,34,90,56,42,15,74,64,56,46,39,89,86,10,71,69,40,50,50,25,22,12,45,87,64,55,10,28,77,6,51,87,64,45),
    new MaddenPlayerModel("Damien Wilson","KC","LOLB",70,81,87,74,72,30,60,15,25,6,82,39,33,56,61,6,27,80,82,33,6,35,66,29,19,11,85,80,6,48,57,47,15,78,22,29,12,81,87,47,17,15,20,41,6,73,76,32,10),
    new MaddenPlayerModel("Jordan Lucas","KC","FS",69,91,93,69,62,22,66,38,60,6,76,73,71,25,32,6,35,89,52,25,6,76,61,35,28,22,92,81,6,70,65,26,21,50,25,25,15,53,90,65,67,11,35,76,6,48,93,73,45),
    new MaddenPlayerModel("Dorian O'Daniel","KC","LOLB",69,85,87,73,67,23,63,51,49,6,80,10,10,10,10,6,74,85,31,16,6,10,22,15,5,5,71,90,6,13,18,10,15,35,17,35,12,83,83,40,6,10,70,10,6,25,62,11,10),
    new MaddenPlayerModel("Juan Thornhill","KC","FS",69,89,88,66,67,52,73,60,63,18,74,52,50,64,58,6,45,85,82,54,6,61,79,15,5,5,88,65,6,45,58,35,25,73,20,20,12,73,93,60,29,10,45,40,6,59,87,44,10),
    new MaddenPlayerModel("Tanoh Kpassagnon","KC","RE",68,79,85,82,65,15,47,42,44,6,77,73,68,29,33,6,35,90,57,32,6,77,68,38,30,22,86,84,6,72,68,32,37,52,19,14,14,48,91,65,60,12,35,72,6,53,90,73,70),
    new MaddenPlayerModel("Cameron Erving","KC","LG",67,67,75,84,78,30,45,15,15,6,54,55,74,30,30,6,12,87,68,51,6,69,60,20,10,10,89,77,6,66,72,51,15,71,21,21,12,45,87,62,52,10,10,71,6,48,90,62,50),
    new MaddenPlayerModel("Charvarius Ward","KC","CB",67,91,86,59,71,21,57,55,60,21,70,78,71,10,10,30,40,87,35,79,35,82,19,66,60,52,86,86,15,12,12,63,50,28,27,18,24,53,87,84,82,11,56,20,20,34,80,79,67),
    new MaddenPlayerModel("David Wells","KC","TE",67,81,88,76,51,53,75,69,71,33,40,62,55,45,35,6,35,76,59,25,6,75,78,10,10,10,93,75,6,64,70,26,30,67,22,19,15,46,94,65,55,13,35,70,6,55,86,65,74),
    new MaddenPlayerModel("Byron Pringle","KC","WR",66,90,91,61,60,35,79,76,82,6,29,58,64,41,47,6,12,88,50,33,6,68,70,29,19,12,81,87,6,69,73,44,18,53,15,11,12,48,89,52,62,12,10,72,6,49,85,72,61),
    new MaddenPlayerModel("Andrew Wylie","KC","RG",66,68,82,82,70,25,35,30,30,5,33,70,51,10,10,15,62,89,33,70,20,59,15,60,56,51,84,83,10,12,15,63,61,31,17,13,20,68,87,75,61,10,63,15,10,37,82,53,40)
  ];

  public static chartColors: Color[] = [{
      borderColor: 'rgba(30,144,255,0.4)',
      backgroundColor: 'rgba(30,144,255,0.2)',
    },
    {
      borderColor: 'rgba(255,30,144,0.4)',
      backgroundColor: 'rgba(255,30,144,0.2)',
    },
    {
      borderColor: 'rgba(144,255,30,0.4)',
      backgroundColor: 'rgba(144,255,30,0.2)',
    }
  ];

  public static smartItemModels = [
    new ItemModel(
      "1",
      "5th Avenue Cap-Toe Oxford",
      "Elite comfort at a reasonable price.  A classic shoe that never goes out of style.",
      "https://www.allenedmonds.com/dw/image/v2/AAXI_PRD/on/demandware.static/-/Sites-allenedmonds-catalog/default/dwbff6785f/images/2.1/mens-shoes/fifthave-5735-walnut-angle-web.jpg?sw=2000&sh=2000&sm=fit",
      "Allen Edmunds",
      395.00,
      "OFFICE",
      "IN STOCK",
      5,
      .10
    ),
    new ItemModel(
      "2",
      "McAllister Wingtip Oxford",
      "Elite comfort at a reasonable price.  A classic shoe that never goes out of style.",
      "https://www.allenedmonds.com/dw/image/v2/AAXI_PRD/on/demandware.static/-/Sites-allenedmonds-catalog/default/dw57cd0bda/images/2.1/mens-shoes/mcallister-6235-walnut-angle-web.jpg?sw=2000&sh=2000&sm=fit",
      "Allen Edmunds",
      395.00,
      "OFFICE",
      "IN STOCK",
      4.5,
      .35
    ),
    new ItemModel(
      "3",
      "5th Avenue Cap-Toe Oxford",
      "Elite comfort at a reasonable price.  A classic shoe that never goes out of style.",
      "https://www.allenedmonds.com/dw/image/v2/AAXI_PRD/on/demandware.static/-/Sites-allenedmonds-catalog/default/dwbff6785f/images/2.1/mens-shoes/fifthave-5735-walnut-angle-web.jpg?sw=2000&sh=2000&sm=fit",
      "Allen Edmunds",
      249.99,
      "OFFICE",
      "IN STOCK",
      5,
      .10
    ),
    new ItemModel(
      "4",
      "5th Avenue Cap-Toe Oxford",
      "Elite comfort at a reasonable price.  A classic shoe that never goes out of style.",
      "https://www.allenedmonds.com/dw/image/v2/AAXI_PRD/on/demandware.static/-/Sites-allenedmonds-catalog/default/dwbff6785f/images/2.1/mens-shoes/fifthave-5735-walnut-angle-web.jpg?sw=2000&sh=2000&sm=fit",
      "Allen Edmunds",
      249.99,
      "OFFICE",
      "IN STOCK",
      5,
      .10
    ),
    new ItemModel(
      "5",
      "5th Avenue Cap-Toe Oxford",
      "Elite comfort at a reasonable price.  A classic shoe that never goes out of style.",
      "https://www.allenedmonds.com/dw/image/v2/AAXI_PRD/on/demandware.static/-/Sites-allenedmonds-catalog/default/dwbff6785f/images/2.1/mens-shoes/fifthave-5735-walnut-angle-web.jpg?sw=2000&sh=2000&sm=fit",
      "Allen Edmunds",
      249.99,
      "OFFICE",
      "IN STOCK",
      5,
      .10
    ),
    new ItemModel(
      "6",
      "5th Avenue Cap-Toe Oxford",
      "Elite comfort at a reasonable price.  A classic shoe that never goes out of style.",
      "https://www.allenedmonds.com/dw/image/v2/AAXI_PRD/on/demandware.static/-/Sites-allenedmonds-catalog/default/dwbff6785f/images/2.1/mens-shoes/fifthave-5735-walnut-angle-web.jpg?sw=2000&sh=2000&sm=fit",
      "Allen Edmunds",
      249.99,
      "OFFICE",
      "IN STOCK",
      5,
      .10
    ),
    new ItemModel(
      "7",
      "5th Avenue Cap-Toe Oxford",
      "Elite comfort at a reasonable price.  A classic shoe that never goes out of style.",
      "https://www.allenedmonds.com/dw/image/v2/AAXI_PRD/on/demandware.static/-/Sites-allenedmonds-catalog/default/dwbff6785f/images/2.1/mens-shoes/fifthave-5735-walnut-angle-web.jpg?sw=2000&sh=2000&sm=fit",
      "Allen Edmunds",
      249.99,
      "OFFICE",
      "IN STOCK",
      5,
      .10
    ),
    new ItemModel(
      "8",
      "5th Avenue Cap-Toe Oxford",
      "Elite comfort at a reasonable price.  A classic shoe that never goes out of style.",
      "https://www.allenedmonds.com/dw/image/v2/AAXI_PRD/on/demandware.static/-/Sites-allenedmonds-catalog/default/dwbff6785f/images/2.1/mens-shoes/fifthave-5735-walnut-angle-web.jpg?sw=2000&sh=2000&sm=fit",
      "Allen Edmunds",
      249.99,
      "OFFICE",
      "IN STOCK",
      5,
      .10
    ),
    new ItemModel(
      "9",
      "5th Avenue Cap-Toe Oxford",
      "Elite comfort at a reasonable price.  A classic shoe that never goes out of style.",
      "https://www.allenedmonds.com/dw/image/v2/AAXI_PRD/on/demandware.static/-/Sites-allenedmonds-catalog/default/dwbff6785f/images/2.1/mens-shoes/fifthave-5735-walnut-angle-web.jpg?sw=2000&sh=2000&sm=fit",
      "Allen Edmunds",
      249.99,
      "OFFICE",
      "IN STOCK",
      5,
      .10
    ),
    new ItemModel(
      "10",
      "5th Avenue Cap-Toe Oxford",
      "Elite comfort at a reasonable price.  A classic shoe that never goes out of style.",
      "https://www.allenedmonds.com/dw/image/v2/AAXI_PRD/on/demandware.static/-/Sites-allenedmonds-catalog/default/dwbff6785f/images/2.1/mens-shoes/fifthave-5735-walnut-angle-web.jpg?sw=2000&sh=2000&sm=fit",
      "Allen Edmunds",
      249.99,
      "OFFICE",
      "IN STOCK",
      5,
      .10
    ),
    new ItemModel(
      "11",
      "5th Avenue Cap-Toe Oxford",
      "Elite comfort at a reasonable price.  A classic shoe that never goes out of style.",
      "https://www.allenedmonds.com/dw/image/v2/AAXI_PRD/on/demandware.static/-/Sites-allenedmonds-catalog/default/dwbff6785f/images/2.1/mens-shoes/fifthave-5735-walnut-angle-web.jpg?sw=2000&sh=2000&sm=fit",
      "Allen Edmunds",
      249.99,
      "OFFICE",
      "IN STOCK",
      5,
      .10
    ),
    new ItemModel(
      "12",
      "5th Avenue Cap-Toe Oxford",
      "Elite comfort at a reasonable price.  A classic shoe that never goes out of style.",
      "https://www.allenedmonds.com/dw/image/v2/AAXI_PRD/on/demandware.static/-/Sites-allenedmonds-catalog/default/dwbff6785f/images/2.1/mens-shoes/fifthave-5735-walnut-angle-web.jpg?sw=2000&sh=2000&sm=fit",
      "Allen Edmunds",
      249.99,
      "OFFICE",
      "IN STOCK",
      5,
      .10
    ),
    new ItemModel(
      "13",
      "5th Avenue Cap-Toe Oxford",
      "Elite comfort at a reasonable price.  A classic shoe that never goes out of style.",
      "https://www.allenedmonds.com/dw/image/v2/AAXI_PRD/on/demandware.static/-/Sites-allenedmonds-catalog/default/dwbff6785f/images/2.1/mens-shoes/fifthave-5735-walnut-angle-web.jpg?sw=2000&sh=2000&sm=fit",
      "Allen Edmunds",
      249.99,
      "OFFICE",
      "IN STOCK",
      5,
      .10
    ),
    new ItemModel(
      "14",
      "5th Avenue Cap-Toe Oxford",
      "Elite comfort at a reasonable price.  A classic shoe that never goes out of style.",
      "https://www.allenedmonds.com/dw/image/v2/AAXI_PRD/on/demandware.static/-/Sites-allenedmonds-catalog/default/dwbff6785f/images/2.1/mens-shoes/fifthave-5735-walnut-angle-web.jpg?sw=2000&sh=2000&sm=fit",
      "Allen Edmunds",
      249.99,
      "OFFICE",
      "IN STOCK",
      5,
      .10
    ),
    new ItemModel(
      "15",
      "5th Avenue Cap-Toe Oxford",
      "Elite comfort at a reasonable price.  A classic shoe that never goes out of style.",
      "https://www.allenedmonds.com/dw/image/v2/AAXI_PRD/on/demandware.static/-/Sites-allenedmonds-catalog/default/dwbff6785f/images/2.1/mens-shoes/fifthave-5735-walnut-angle-web.jpg?sw=2000&sh=2000&sm=fit",
      "Allen Edmunds",
      249.99,
      "OFFICE",
      "IN STOCK",
      5,
      .10
    ),
    new ItemModel(
      "16",
      "5th Avenue Cap-Toe Oxford",
      "Elite comfort at a reasonable price.  A classic shoe that never goes out of style.",
      "https://www.allenedmonds.com/dw/image/v2/AAXI_PRD/on/demandware.static/-/Sites-allenedmonds-catalog/default/dwbff6785f/images/2.1/mens-shoes/fifthave-5735-walnut-angle-web.jpg?sw=2000&sh=2000&sm=fit",
      "Allen Edmunds",
      249.99,
      "OFFICE",
      "IN STOCK",
      5,
      .10
    ),
    new ItemModel(
      "17",
      "5th Avenue Cap-Toe Oxford",
      "Elite comfort at a reasonable price.  A classic shoe that never goes out of style.",
      "https://www.allenedmonds.com/dw/image/v2/AAXI_PRD/on/demandware.static/-/Sites-allenedmonds-catalog/default/dwbff6785f/images/2.1/mens-shoes/fifthave-5735-walnut-angle-web.jpg?sw=2000&sh=2000&sm=fit",
      "Allen Edmunds",
      249.99,
      "OFFICE",
      "IN STOCK",
      5,
      .10
    ),
    new ItemModel(
      "18",
      "5th Avenue Cap-Toe Oxford",
      "Elite comfort at a reasonable price.  A classic shoe that never goes out of style.",
      "https://www.allenedmonds.com/dw/image/v2/AAXI_PRD/on/demandware.static/-/Sites-allenedmonds-catalog/default/dwbff6785f/images/2.1/mens-shoes/fifthave-5735-walnut-angle-web.jpg?sw=2000&sh=2000&sm=fit",
      "Allen Edmunds",
      249.99,
      "OFFICE",
      "IN STOCK",
      5,
      .10
    ),
    new ItemModel(
      "19",
      "5th Avenue Cap-Toe Oxford",
      "Elite comfort at a reasonable price.  A classic shoe that never goes out of style.",
      "https://www.allenedmonds.com/dw/image/v2/AAXI_PRD/on/demandware.static/-/Sites-allenedmonds-catalog/default/dwbff6785f/images/2.1/mens-shoes/fifthave-5735-walnut-angle-web.jpg?sw=2000&sh=2000&sm=fit",
      "Allen Edmunds",
      249.99,
      "OFFICE",
      "IN STOCK",
      5,
      .10
    ),
    new ItemModel(
      "20",
      "5th Avenue Cap-Toe Oxford",
      "Elite comfort at a reasonable price.  A classic shoe that never goes out of style.",
      "https://www.allenedmonds.com/dw/image/v2/AAXI_PRD/on/demandware.static/-/Sites-allenedmonds-catalog/default/dwbff6785f/images/2.1/mens-shoes/fifthave-5735-walnut-angle-web.jpg?sw=2000&sh=2000&sm=fit",
      "Allen Edmunds",
      249.99,
      "OFFICE",
      "IN STOCK",
      5,
      .10
    ),
    new ItemModel(
      "21",
      "5th Avenue Cap-Toe Oxford",
      "Elite comfort at a reasonable price.  A classic shoe that never goes out of style.",
      "https://www.allenedmonds.com/dw/image/v2/AAXI_PRD/on/demandware.static/-/Sites-allenedmonds-catalog/default/dwbff6785f/images/2.1/mens-shoes/fifthave-5735-walnut-angle-web.jpg?sw=2000&sh=2000&sm=fit",
      "Allen Edmunds",
      249.99,
      "OFFICE",
      "IN STOCK",
      5,
      .10
    ),
    new ItemModel(
      "22",
      "5th Avenue Cap-Toe Oxford",
      "Elite comfort at a reasonable price.  A classic shoe that never goes out of style.",
      "https://www.allenedmonds.com/dw/image/v2/AAXI_PRD/on/demandware.static/-/Sites-allenedmonds-catalog/default/dwbff6785f/images/2.1/mens-shoes/fifthave-5735-walnut-angle-web.jpg?sw=2000&sh=2000&sm=fit",
      "Allen Edmunds",
      249.99,
      "OFFICE",
      "IN STOCK",
      5,
      .10
    ),
    new ItemModel(
      "23",
      "5th Avenue Cap-Toe Oxford",
      "Elite comfort at a reasonable price.  A classic shoe that never goes out of style.",
      "https://www.allenedmonds.com/dw/image/v2/AAXI_PRD/on/demandware.static/-/Sites-allenedmonds-catalog/default/dwbff6785f/images/2.1/mens-shoes/fifthave-5735-walnut-angle-web.jpg?sw=2000&sh=2000&sm=fit",
      "Allen Edmunds",
      249.99,
      "OFFICE",
      "IN STOCK",
      5,
      .10
    ),
    new ItemModel(
      "24",
      "5th Avenue Cap-Toe Oxford",
      "Elite comfort at a reasonable price.  A classic shoe that never goes out of style.",
      "https://www.allenedmonds.com/dw/image/v2/AAXI_PRD/on/demandware.static/-/Sites-allenedmonds-catalog/default/dwbff6785f/images/2.1/mens-shoes/fifthave-5735-walnut-angle-web.jpg?sw=2000&sh=2000&sm=fit",
      "Allen Edmunds",
      249.99,
      "OFFICE",
      "IN STOCK",
      5,
      .10
    ),
    new ItemModel(
      "25",
      "5th Avenue Cap-Toe Oxford",
      "Elite comfort at a reasonable price.  A classic shoe that never goes out of style.",
      "https://www.allenedmonds.com/dw/image/v2/AAXI_PRD/on/demandware.static/-/Sites-allenedmonds-catalog/default/dwbff6785f/images/2.1/mens-shoes/fifthave-5735-walnut-angle-web.jpg?sw=2000&sh=2000&sm=fit",
      "Allen Edmunds",
      249.99,
      "OFFICE",
      "IN STOCK",
      5,
      .10
    ),
    new ItemModel(
      "26",
      "5th Avenue Cap-Toe Oxford",
      "Elite comfort at a reasonable price.  A classic shoe that never goes out of style.",
      "https://www.allenedmonds.com/dw/image/v2/AAXI_PRD/on/demandware.static/-/Sites-allenedmonds-catalog/default/dwbff6785f/images/2.1/mens-shoes/fifthave-5735-walnut-angle-web.jpg?sw=2000&sh=2000&sm=fit",
      "Allen Edmunds",
      249.99,
      "OFFICE",
      "IN STOCK",
      5,
      .10
    ),
    new ItemModel(
      "27",
      "5th Avenue Cap-Toe Oxford",
      "Elite comfort at a reasonable price.  A classic shoe that never goes out of style.",
      "https://www.allenedmonds.com/dw/image/v2/AAXI_PRD/on/demandware.static/-/Sites-allenedmonds-catalog/default/dwbff6785f/images/2.1/mens-shoes/fifthave-5735-walnut-angle-web.jpg?sw=2000&sh=2000&sm=fit",
      "Allen Edmunds",
      249.99,
      "OFFICE",
      "IN STOCK",
      5,
      .10
    ),
    new ItemModel(
      "28",
      "5th Avenue Cap-Toe Oxford",
      "Elite comfort at a reasonable price.  A classic shoe that never goes out of style.",
      "https://www.allenedmonds.com/dw/image/v2/AAXI_PRD/on/demandware.static/-/Sites-allenedmonds-catalog/default/dwbff6785f/images/2.1/mens-shoes/fifthave-5735-walnut-angle-web.jpg?sw=2000&sh=2000&sm=fit",
      "Allen Edmunds",
      249.99,
      "OFFICE",
      "IN STOCK",
      5,
      .10
    ),
    new ItemModel(
      "29",
      "5th Avenue Cap-Toe Oxford",
      "Elite comfort at a reasonable price.  A classic shoe that never goes out of style.",
      "https://www.allenedmonds.com/dw/image/v2/AAXI_PRD/on/demandware.static/-/Sites-allenedmonds-catalog/default/dwbff6785f/images/2.1/mens-shoes/fifthave-5735-walnut-angle-web.jpg?sw=2000&sh=2000&sm=fit",
      "Allen Edmunds",
      249.99,
      "OFFICE",
      "IN STOCK",
      5,
      .10
    ),
    new ItemModel(
      "30",
      "5th Avenue Cap-Toe Oxford",
      "Elite comfort at a reasonable price.  A classic shoe that never goes out of style.",
      "https://www.allenedmonds.com/dw/image/v2/AAXI_PRD/on/demandware.static/-/Sites-allenedmonds-catalog/default/dwbff6785f/images/2.1/mens-shoes/fifthave-5735-walnut-angle-web.jpg?sw=2000&sh=2000&sm=fit",
      "Allen Edmunds",
      249.99,
      "OFFICE",
      "IN STOCK",
      5,
      .10
    ),

  ];

}
