import {StatesGdpModel} from './states-gdp.model';
import {SliceModel} from './slice.model';

export class GdpServiceStateModel{
  public statesGdpModel: StatesGdpModel[] = [];
  public slice: SliceModel = new SliceModel();

  constructor(slice?: SliceModel) {
  }
}
