import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {CityModel} from '../models/city.model';
import {StatesGdpModel} from '../models/states-gdp.model';
import {HttpClient} from '@angular/common/http';
import {GdpServiceStateModel} from '../models/gdp-service-state.model';
import {SliceModel} from '../models/slice.model';
import {filter} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class StateGDPService {
  constructor(private http: HttpClient) {
  }

  statesGDPSubject = new Subject<GdpServiceStateModel>();
  gdpServiceState = new GdpServiceStateModel();

  statesGDP: StatesGdpModel[] = [];
  slice:{start:number, end:number} = {start:40, end:50};

  fetchAllStatesGDP(){
    this.http.get<CityModel[]>("https://resume-demo-8cb78.firebaseio.com/gdp.json").subscribe(statesGDPObject => {
      this.getStatesGDP(this.getStateGDPArray(statesGDPObject), new SliceModel(), true);
    });
  }

  getSlice(slice: SliceModel){
    if(this.statesGDP.length == 0){
      this.http.get<CityModel[]>("https://resume-demo-8cb78.firebaseio.com/gdp.json").subscribe(statesGDPObject => {
        this.getStatesGDP(this.getStateGDPArray(statesGDPObject), slice, true);
      });
    } else {
      this.getStatesGDP(this.statesGDP, slice, true);
    }

  }

  private getStateGDPArray(statesGDPObject: any){
    const statesGDP:StatesGdpModel[] = []
    for (var key in statesGDPObject) {
      if (statesGDPObject.hasOwnProperty(key)) {
        statesGDP.push(
          new StatesGdpModel(
            statesGDPObject[key].name,
            +statesGDPObject[key].gdp2ndQua2020,
            +statesGDPObject[key].gdpPerCapita,
            statesGDPObject[key].region
          )
        );
      }
    }
    return statesGDP;
  }

  private getStatesGDP(statesGDP: StatesGdpModel[], slice?: SliceModel, emit?: boolean){
    if(slice){
      this.gdpServiceState.slice = this.slice = slice;
    }

    this.gdpServiceState.statesGdpModel = this.statesGDP = statesGDP;
    if(slice && emit){
      this.gdpServiceState.statesGdpModel = this.returnSlice(statesGDP, this.gdpServiceState.slice)
      this.statesGDPSubject.next(this.gdpServiceState);
    }
  }

  private returnSlice(filteredSubset:  StatesGdpModel[], slice: SliceModel) {
    const startPercentile = (slice.start / 100) * filteredSubset.length;
    const endPercentile = (slice.end / 100) * filteredSubset.length;

    if (filteredSubset.length >= endPercentile) {
      return filteredSubset.slice(startPercentile, endPercentile);
    } else {
      return [];
    }
  }
}
