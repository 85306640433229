import {Component, OnInit} from '@angular/core';
import { ChartType, ChartOptions } from 'chart.js';
import {SingleDataSet, Label, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip, Color} from 'ng2-charts';
import {StateGDPService} from '../../../root-services/state-gdp.service';
import {ArrayUtil} from '../../../util/array.util';
import {SliceModel} from '../../../models/slice.model';


@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent implements OnInit{
  constructor(private stateGDPService: StateGDPService) {
    monkeyPatchChartJsTooltip();
    monkeyPatchChartJsLegend();

    this.stateGDPService.statesGDPSubject.subscribe(statesGDPState =>{
      const labels =[];
      const data =[];

      this.rangeValues = [statesGDPState.slice.start, statesGDPState.slice.end];
      for (let s of statesGDPState.statesGdpModel){
        labels.push([s.name]);
        data.push(s.gdp2ndQua2020);
      }
      this.pieChartLabels = labels;
      this.pieChartData = data;
    });
  }

  rangeValues: number[] = [0, 8];

  public pieChartOptions: ChartOptions = {
    responsive: true,
  };
  public pieChartLabels: Label[] = [];
  public pieChartData: SingleDataSet = [];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [];
  public pieChartColors = ArrayUtil.chartColors;

  ngOnInit(): void {
    this.stateGDPService.getSlice(new SliceModel(this.rangeValues[0], this.rangeValues[1]));
  }

  onSliderChange(newValue:any){
    this.stateGDPService.getSlice(new SliceModel(this.rangeValues[0], this.rangeValues[1]));
  }




}
