import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';


export declare var Cesium:any;

if (environment.production) {
  enableProdMode();
}


Cesium.Ion.defaultAccessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIwMGYyNjE1MS05ZTU4LTQ1MDQtYjZhMC00MzlmOTdmMWE1YTMiLCJpZCI6Mzg0MjQsImlhdCI6MTYwNjMyNzY2NH0.QILQkWF0cqn69xDkynZQGgdpKeqdjl27Ok-RYPdLEnk';
Cesium.buildModuleUrl.setBaseUrl('/assets/cesium/');

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
