import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CityModel} from '../models/city.model';
import {Subject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class CityPopulationService {

  constructor(private http: HttpClient) {
  }

  citiesSubject = new Subject<CityModel[]>();
  cities: CityModel[] = []

  fetchAllCities(){
    this.http.get<CityModel[]>("https://resume-demo-8cb78.firebaseio.com/cities.json").subscribe(citiesObject => {
      this.initCities(citiesObject, true);
    });
  }

  getSlice(start:number, end:number){
    if(this.cities.length ==0){
      this.http.get<CityModel[]>("https://resume-demo-8cb78.firebaseio.com/cities.json").subscribe(citiesObject => {
        this.initCities(citiesObject, true);
        this.emitSlice(start, end);
      });
    } else {
      this.emitSlice(start, end);
    }
  }

  sortByState(states: {name: string, code: string} []){
    if(this.cities.length == 0){
      this.fetchAllCities();
      this.emitSortByState(states);
    } else {
      this.emitSortByState(states);
    }
  }

  sortByStateWithRange(states: {name: string, code: string} [], start:number, end:number){
    if(states.length == 0){
      this.getSlice(start, end);
    } else {
      const citiesFilteredByState = this.getCitiesFilteredByStates(states);
      this.citiesSubject.next(this.returnSlice(citiesFilteredByState, start, end));
    }
  }

  emitAllCities(){
    this.citiesSubject.next(this.cities);
  }

  private returnSlice(filteredSubset:  CityModel[], start:number, end:number) {
    const startPercentile = (start / 100) * filteredSubset.length;
    const endPercentile = (end / 100) * filteredSubset.length;

    if (filteredSubset.length >= endPercentile) {
      return filteredSubset.slice(startPercentile, endPercentile);
    } else {
      return [];
    }
  }

  private emitSlice(start:number, end:number){
    const startPercentile = (start / 100) * this.cities.length;
    const endPercentile = (end / 100) * this.cities.length;

    if (this.cities.length >= endPercentile) {
      this.citiesSubject.next(this.cities.slice(startPercentile, endPercentile));
    }
  }

  private emitSortByState(states: {name: string, code: string} []){
    if(states.length == 0){
      this.citiesSubject.next(this.cities);
    } else {
      const filteredCities = [];
      for (let i = 0; i < this.cities.length; i++) {
        for (let j = 0; j < states.length; j++) {
          if (this.cities[i].stateAbbrev.toUpperCase() ==
            states[j].code.toUpperCase()) {
            filteredCities.push(this.cities[i]);
          }
        }
      }
      this.citiesSubject.next(filteredCities);
    }
  }

  private getCitiesFilteredByStates(states: {name: string, code: string} []){
    const filteredCities = [];
    if(states.length > 0){
      for (let i = 0; i < this.cities.length; i++) {
        for (let j = 0; j < states.length; j++) {
          if (this.cities[i].stateAbbrev.toUpperCase() ==
            states[j].code.toUpperCase()) {
            filteredCities.push(this.cities[i]);
          }
        }
      }
    }
    return filteredCities;
  }

  private initCities(citiesObject: any, emit?: boolean){
    this.cities = []
    for (var key in citiesObject) {
      if (citiesObject.hasOwnProperty(key)) {
        this.cities.push(
          new CityModel(
            +citiesObject[key].id,
            citiesObject[key].city,
            citiesObject[key].state,
            citiesObject[key].stateAbbrev,
            +citiesObject[key].estimate2019,
            +citiesObject[key].census2010,
            +citiesObject[key].percentChange,
            +citiesObject[key].sqMiLandArea2016,
            +citiesObject[key].sqKmLandArea2016,
            +citiesObject[key].sqMiPopDensity2016,
            +citiesObject[key].sqKmPopDensity2016,
            +citiesObject[key].latitude,
            +citiesObject[key].longitude
          )
        );
      }
    }
    if(emit){
      this.citiesSubject.next(this.cities);
    }
  }
}
