<p-toolbar>
  <div class="ui-toolbar-group-left">
    <button pButton pRipple type="button" class="p-button"
            routerLink="/"
            routerLinkActive="active"
            label="Home">
      <img  src="assets/icons/house-door.svg"
            alt="Home" width="32" height="32" title="Home">
    </button>

    <button pButton pRipple type="button" class="p-button"
            routerLink="/charts"
            routerLinkActive="active"
            label="Charts">
      <img
        src="assets/icons/bar-chart.svg"
        alt="Charts" width="32" height="32" title="Charts">
    </button>

    <button pButton pRipple type="button" class="p-button"
            routerLink="/cesium"
            routerLinkActive="active"
            label="Cesium">
      <img
        src="assets/icons/globe.svg"
        alt="Cesium" width="32" height="32" title="Cesium">
    </button>

<!--    <button pButton pRipple type="button" class="p-button"-->
<!--            routerLink="/tarokka"-->
<!--            routerLinkActive="active"-->
<!--            label="Tarokka">-->
<!--      <img-->
<!--        src="assets/icons/files.svg"-->
<!--        alt="Tarokka" width="32" height="32" title="Tarokka">-->
<!--    </button>-->

<!--    <button pButton pRipple type="button" class="p-button"-->
<!--            routerLink="/shopping-cart"-->
<!--            routerLinkActive="active"-->
<!--            label="S-Mart">-->
<!--      <img-->
<!--        src="assets/icons/cart3.svg"-->
<!--        alt="S-Mart" width="32" height="32" title="S-Mart">-->
<!--    </button>-->

    <button pButton pRipple type="button" class="p-button" label="NG 10 Course Project" (click)="forwardToCourseProject()">
      <img
        src="assets/icons/bookmark-star.svg"
        alt="Angular 10 Course Project" width="32" height="32" title="Angular 10 Course Project">
    </button>

  </div>
  <div class="ui-toolbar-group-right">
    <img style="cursor: pointer"
         [hidden]="hideButton"
         (click)="showBasicDialog()"
         src="assets/icons/info-circle.svg"
         alt="Info" width="32" height="32" title="Info" class="no-button-img">
    <p-sidebar [(visible)]="display" position="right" styleClass="p-sidebar-lg" [baseZIndex]="10000">
      <h1 style="font-weight:normal">{{info.title}}</h1>
      <h3 style="font-weight:normal">{{info.contentA}}</h3>
      <br>
      <h3 style="font-weight:normal">{{info.contentB}}</h3>
      <br>
      <h3 style="font-weight:normal">{{info.contentC}}</h3>
      <div style="text-align: right">
        <button pButton pRipple type="button" class="p-button"
                (click)="display = false"
                style="background-color: #c3ffc3"
                label="Got it!">
          <img  src="assets/icons/check2.svg"
                alt="OK" width="32" height="32" title="OK">
        </button>
      </div>
    </p-sidebar>
  </div>
</p-toolbar>
<br>






