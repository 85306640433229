import {AfterContentInit, Component, Input} from '@angular/core';
import {CardState} from './card-state.model';
import {Card} from "./card.model";
import {DeckService} from '../../deck/deck.service';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})

export class CardComponent{
  @Input()
  position: number = -1;

  card: Card;
  displayUrl: string = "../../assets/img/transparentcard.png";

  constructor(private deckService:DeckService) {
    this.card = new Card("", "", "../../assets/img/backside2.png");

    if(this.position == 0 ){
      this.displayUrl = "../../assets/img/placement_loc1.png";
    } else if(this.position == 1 ){
      this.displayUrl = "../../assets/img/placement_loc2.png";
    } else if(this.position == 2 ){
      this.displayUrl = "../../assets/img/placement_loc3.png";
    } else if(this.position == 3 ){
      this.displayUrl = "../../assets/img/placement_loc4.png";
    } else if(this.position == 4 ) {
      this.displayUrl = "../../assets/img/placement_loc5.png";
    }

    this.deckService.positionSubject.subscribe((cards) => {
      if(this.position > -1){
        for(var i=0; i < cards.length; i++){
          if(i == this.position) {
             this.card = cards[i];
             this.card = new Card(cards[i].getName(), cards[i].getDescription(), cards[i].getImgUrl());
             this.card.state = cards[i].state;
          }
        }

        if(this.card.state == CardState.Undrawn){
          if(this.position == 0 ){
            this.displayUrl = "../../assets/img/placement_loc1.png";
          } else if(this.position == 1 ){
            this.displayUrl = "../../assets/img/placement_loc2.png";
          } else if(this.position == 2 ){
            this.displayUrl = "../../assets/img/placement_loc3.png";
          } else if(this.position == 3 ){
            this.displayUrl = "../../assets/img/placement_loc4.png";
          } else if(this.position == 4 ){
            this.displayUrl = "../../assets/img/placement_loc5.png";

          }
        } else if(this.card.state == CardState.Unrevealed){
          this.displayUrl = "../../assets/img/backside2.png";
        } else {
          this.displayUrl = this.card.getImgUrl();
        }
      }
    });
  }
}


