import { Component } from '@angular/core';
import { ChartType } from 'chart.js';
import { MultiDataSet, Label } from 'ng2-charts';
import {StateGDPService} from '../../../root-services/state-gdp.service';
import {SliceModel} from '../../../models/slice.model';
import {ArrayUtil} from '../../../util/array.util';

@Component({
  selector: 'app-doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.scss']
})
export class DoughnutChartComponent {

  constructor(private stateGDPService: StateGDPService) {
    this.stateGDPService.statesGDPSubject.subscribe(statesGDPState =>{
      const labels =[];
      const data =[];

      this.rangeValues = [statesGDPState.slice.start, statesGDPState.slice.end];
      for (let s of statesGDPState.statesGdpModel){
        labels.push([s.name]);
        data.push(s.gdpPerCapita);
      }
      this.doughnutChartLabels = labels;
      this.doughnutChartData = [data];
    });
  }

  rangeValues: number[] = [0, 8];

  doughnutChartLabels: Label[] = [];
  doughnutChartData: MultiDataSet = [
    [55, 25, 20]
  ];
  doughnutChartType: ChartType = 'doughnut';
  doughnutChartColors = ArrayUtil.chartColors;

  onSliderChange(newValue:any){
    this.stateGDPService.getSlice(new SliceModel(this.rangeValues[0], this.rangeValues[1]));
  }

}
