import {Component, OnInit, ViewChild} from '@angular/core';
import {from, Observable} from 'rxjs';
import {AcLayerComponent, AcNotification, ActionType} from 'angular-cesium';
import {CylinderDataService} from '../cylinder-data.service';
import {MockCylinderProviderService} from '../../../service/mock-cylinder-provider.service';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-cylinder-layer',
  templateUrl: './cylinder-layer.component.html',
  styleUrls: ['./cylinder-layer.component.scss']
})
export class CylinderLayerComponent implements OnInit{
  // @ViewChild(AcLayerComponent, {static: false}) layer: AcLayerComponent | undefined;
  entities$: Observable<AcNotification> = new Observable<AcNotification>();
  Cesium = Cesium;

  constructor(private data: CylinderDataService) {}

  getTop10(){
    this.entities$.pipe(map(entity => ({
      id: entity.id,
      actionType: ActionType.ADD_UPDATE,
      entity: entity,
    })));
  }

  deleteNYC(){
    this.entities$.pipe(map(entity => ({
      id: '1',
      actionType: ActionType.DELETE
    })));
  }


  ngOnInit(): void {
     //this.entities$ = this.realData.getEntities();
    this.entities$ = this.data.get$(20).pipe(map(entity => ({
          id: entity.id,
          actionType: ActionType.ADD_UPDATE,
          entity: entity,
        })));

  }

}
