import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {AcNotification, ActionType} from 'angular-cesium';
import {Plane} from '../../../model/plane.model';
import {PlanesService} from '../../../service/planes.service';

@Component({
  selector: 'app-plane-layer',
  templateUrl: './plane-layer.component.html',
  styleUrls: ['./plane-layer.component.scss']
})
export class PlaneLayerComponent implements OnInit {
  planes$: Observable<AcNotification>  = new Observable<AcNotification>();

  constructor(private planesService: PlanesService) {
  }

  ngOnInit() {
    this.planesService.planes.subscribe((planes) => {
      for (var i=0; i < planes.length; i++){

      }

    })
    // // this.planesService.planes
    // this.planes$ = this.planesService.getPlanes().pipe(
    //   map(plane => ({
    //     id: plane.id,
    //     actionType: ActionType.ADD_UPDATE,
    //     entity: plane,
    //   }))
    // );
  }

  getColor(plane: Plane) {
    if (plane.name.startsWith('Boeing')) {
      return Cesium.Color.Green;
    } else {
      return Cesium.Color.White;
    }
  }
}

// Example mock service

// @Injectable({
//   providedIn: 'root'
// })
//
// export class PlanesService {
//   private planes = <Plane[]>[
//     {
//       id: '1',
//       position: Cesium.Cartesian3.fromDegrees(30, 30),
//       name: 'Airbus a320',
//       image: 'https://cdn3.iconfinder.com/data/icons/airport-collection/100/23-512.png'
//     },
//     {
//       id: '2',
//       position: Cesium.Cartesian3.fromDegrees(31, 31),
//       name: 'Boeing 777',
//       image: 'https://cdn1.iconfinder.com/data/icons/fly-airbus-and-aeroplane/154/fly-air-plane-airbus-aeroplane-512.png'
//     }
//   ];
//
//   getPlanes() {
//     // Or get it from a real updating data source
//     // return Observable.from(this.planes);
//     // return Subject.next(this.planes);
//     Observable.
//   }
// }

