<div class="pageFont">
  <div class="row">
    <div class="col-md-6">
      <div style="font-size:24.0pt; font-weight: bold;">Brad Pirochta</div>
      <div style="font-size:18.0pt; font-weight: bold;">Principal Software Engineer</div>
    </div>
    <div class="col-md-6" style="text-align: right;">
      <a href="/assets/docs/Brad_Pirochta_2021_01_25_FINAL.docx" download>
        <img  src="assets/icons/file-earmark-word.svg" alt="Word" width="32" height="32" title="Download Word Resume" class="no-button-img">
      </a>
      <a href="/assets/docs/Brad_Pirochta_2021_01_25_FINAL.pdf" download>
        <img  src="assets/icons/pdf-file.svg" alt="PDF" width="32" height="32" title="Download PDF Resume" class="no-button-img">
      </a>
      <a href="/assets/docs/Brad_Pirochta_2021_01_25_FINAL.txt" download>
        <img src="assets/icons/file-earmark-text.svg" alt="Text" width="32" height="32" title="Download Text Resume" class="no-button-img">
      </a> | <img style="cursor: pointer" (click)="displayCert()" src="assets/icons/award.svg" alt="Cert" width="32" height="32" title="Open Angular 10 Certificate" class="no-button-img">
    </div>
  </div>
  <br>
  <div class="row">
    <div class="col-md-12">
      <div style="font-size:14.0pt; font-weight: bold;">Executive Summary:</div>
      <div style="font-size:14.0pt;">Possess expertise in Full Stack Web Application development. Skills include Angular 10, TypeScript, JavaScript,
        HTML 5, CSS 3, NodeJS, Java 9, Gradle, Maven, Spring Boot, Spring Web, Spring Data JPA, Spring Data JDBC, Spring Cloud (Config, Gateway,
        Netflix Eureka, LoadBalancer, OpenFeign), Docker, Kubernetes, MySQL, Oracle, Unix, Windows, and Agile development.
      </div>
      <br>
      <div style="font-size:12.0pt;"><b>Clearances:</b> TS/SCI with CI poly (current, expires 04/2021), Public Trust (current, expires 06/2021)</div>
      <br>
      <p-toolbar>
        <div class="ui-toolbar-group-left pageFont" style="font-size:12.0pt; font-weight: bold;">Experience:</div>
        <div class="ui-toolbar-group-right">
          <button pButton pRipple type="button" class="p-button" (click)="expandExperienceSection()"
                  label="&nbsp;">
            <img
              src="assets/icons/plus.svg"
              alt="Expand All" width="20" height="20" title="Expand All">
          </button>
          <button pButton pRipple type="button" class="p-button" (click)="collapseExperienceSection()"
                  label="&nbsp;">
            <img
              src="assets/icons/dash.svg"
              alt="Collapse All" width="20" height="20" title="Collapse All">
          </button>
        </div>
      </p-toolbar>
      <div style="margin-top: 4px;"></div>
      <p-accordion [multiple]="true">
        <p-accordionTab header="Integrity Applications INC, 2016-2019" class="pageFont" style="font-size:12.0pt;" [(selected)]="experienceSelected[0]">
          <b>Client:</b>&nbsp;<a href="https://www.nro.gov/" target="_blank">United States National Reconnaissance Office (NRO)</a>
          <br><b>Position:</b> Principal Software Engineer
          <div class="top-text-margin">
            Developed the EAGLE web interface using Angular 6, PrimeNG, and a fully functional RESTful client-side service interface that leveraged scores of REST endpoints to provide real-time data to the end user.  EAGLE allows analysts to define variables on multiple satellites (orbits, payloads, ground stations, etc…), and then runs simulations within the given parameters.  Results of the simulation are provided in various formats including tabled data, images, and/or dynamic charting.
          </div>
          <div class="top-text-margin">
            Key developer of the TUTT web application.  TUTT allows the user to extract real time satellite intel using a 3-dimensional globe (Cesium) to define the analysts’ area of interest.
          </div>
          <div class="top-text-margin">
            <b>Skills:</b> Java, Gradle, Angular 6, PrimeNG, TypeScript, CSS 3, HTML 5, Restful web services, JavaScript, Spring Boot, Hibernate, SQL, Gradle, Spring Data JPA, Cesium, and Agile Development.
          </div>
        </p-accordionTab>
        <p-accordionTab header="CACI International INC, 2009-2016" class="pageFont" style="font-size:12.0pt;"  [(selected)]="experienceSelected[1]">
          <b>Client:</b>&nbsp;<a href="https://www.justice.gov/" target="_blank">United States Department of Justice (DoJ)</a>
          <br><b>Position:</b> Senior Software Engineer
          <div class="top-text-margin">
            Primary developer of the Early Case Assessment web application (ECA) & various supporting code-bases.  ECA is a search tool that stores metadata of documents collected as evidence related to assorted federal court cases.  This allowed extensive search capabilities for users to navigate, organize, and cull information related to pertinent cases.
          </div>
          <div class="top-text-margin">
            <b>Skills:</b> Java, GWT, GXT, CSS 3, RESTful web services, Spring, Spring Data JDBC, Maven, SQL, Solr, and Agile Development
          </div>
          <hr>
          <div style="margin-top:10px;">
            <b>Client:</b> &nbsp;<a href="https://dod.defense.gov/" target="_blank">United States Department of Defense (DoD)</a> & <a href="https://www.dia.mil/" target="_blank">United States Defense Intelligence Agency (DIA)</a>
            <br><b>Position:</b> Senior Software Engineer
          </div>
          <div class="top-text-margin">
            Developed and deployed Defense Intelligence Online (DIO).  DIO is a metadata ingestion engine combined with a web application interface.  DIO retrieves metadata (country, target of interest, date, etc..) from internal intelligence websites and processes the information to be stored in an Oracle database and Solr repository.  The web application was then used by the end users to search the Solr repository, present relevant information, and then direct the user to the appropriate intelligence document/website.
          </div>
          <div class="top-text-margin">
            <b>Skills:</b> Java, JavaScript, Spring, Spring Data JDBC, JsonRPC, ExtJS, SQL, Maven, Jira, Oracle, Apache Tomcat</div>
        </p-accordionTab>
        <p-accordionTab header="NuWave Solutions LLC, 2008-2009" class="pageFont" style="font-size:12.0pt;" [(selected)]="experienceSelected[2]">
          <b>Client:</b> Varied
          <br><b>Position:</b> Senior Software Engineer
          <div class="top-text-margin">
            Created Social Network demonstration web application. Maintained various aspects of Department of Defense's portal framework. Contributed to American Chemical Society's Social Network web application.
          </div>
          <div class="top-text-margin">
            <b>Skills:</b> Java, GWT, Struts, JavaScript, HTML, Spring, Spring Data JDBC, SQL, JSR-168 Portlet, WebLogic, MySQL, Oracle
          </div>
        </p-accordionTab>
        <p-accordionTab header="Lockheed Martin Corporation, 2006-2008" class="pageFont" style="font-size:12.0pt;" [(selected)]="experienceSelected[3]">
          <b>Client:</b>&nbsp;<a href="https://en.wikipedia.org/wiki/Counterintelligence_Field_Activity" target="_blank">United States Counter Intelligence Field Activity (CIFA)</a>
          <br><b>Position:</b> Software Engineer
          <div class="top-text-margin">
            Established requirements for various components of Portico. Wrote technical documentation for requirements which was then provided to testers to accept the final product.
          </div>
          <div class="top-text-margin">
            Reviewed resumes and conducted technical interviews. As a result, Lockheed Martin hired approximately 50 cleared software developers over the span of 6 months. This mandated hundreds of interviews which I personally organized and conducted.
          </div>
          <div class="top-text-margin">
            <b>Skills:</b> Java, Spring, J2EE, Oracle OC4J, UML, Eclipse, MyEclipse, Oracle
          </div>
        </p-accordionTab>
        <p-accordionTab header="The Boeing Company, 2004-2006" class="pageFont" style="font-size:12.0pt;" [(selected)]="experienceSelected[4]">
          <b>Client:</b>&nbsp;<a href="https://www.cia.gov/index.html" target="_blank">United States Central Intelligence Agency (CIA)</a>
          <br><b>Position:</b> Software Engineer
          <div class="top-text-margin">
            Designed, documented, and implemented new requirements regarding 2-dimensional imaging geometry involving GPS coordinates and distance vectors using Java Swing.
          </div>
          <div class="top-text-margin">
            Contributed to designing and implementing software using the Rational Unified Process (RUP).
          </div>
          <div class="top-text-margin">
            <b>Skills:</b> Java, UML, RUP, SQL, Database Modeling, Eclipse, Unix, Apache Tomcat
          </div>
        </p-accordionTab>
        <p-accordionTab header="Computer Science Corporation, 2001-2004" class="pageFont" style="font-size:12.0pt;" [(selected)]="experienceSelected[5]">
          <b>Client:</b>&nbsp;<a href="https://www.gdls.com//" target="_blank">General Dynamics Land Systems (GDLS)</a>
          <br><b>Position:</b> Software Engineer
          <div class="top-text-margin">
            Provided full lifecycle support for various software projects, usually, as the sole developer.  This included establishing requirements directly from the customer, designing a solution, estimating the hours, gaining formal approval to proceed, implementing the design, testing the solution, delivering the product to the customer, and maintaining the software as needed.
          </div>
          <div class="top-text-margin">
            <b>Skills:</b> Java, HTML, JavaScript, C++, SQL, Perl, CShell, UML, Oracle, Apache Tomcat
          </div>
        </p-accordionTab>
        <p-accordionTab header="State of Michigan, Michigan Department of State, 1999-2000" class="pageFont" style="font-size:12.0pt;" [(selected)]="experienceSelected[6]">
          <b>Position:</b> Web Designer
          <div class="top-text-margin">
            Designed and developed website for the State of Michigan, Department of Automotive Regulations
          </div>
          <div class="top-text-margin">
            <b>Skills:</b> HTML, CSS, JavaScript
          </div>
        </p-accordionTab>
      </p-accordion>
      <br>
      <p-toolbar>
        <div class="ui-toolbar-group-left pageFont" style="font-size:12.0pt; font-weight: bold;">Education & Certificates:</div>
        <div class="ui-toolbar-group-right">
          <button pButton pRipple type="button" class="p-button" (click)="expandEducationSection()"
                  label="&nbsp;">
            <img
              src="assets/icons/plus.svg"
              alt="Expand All" width="20" height="20" title="Expand All">
          </button>
          <button pButton pRipple type="button" class="p-button" (click)="collapseEducationSection()"
                  label="&nbsp;">
            <img
              src="assets/icons/dash.svg"
              alt="Collapse All" width="20" height="20" title="Collapse All">
          </button>
        </div>
      </p-toolbar>
      <div style="margin-top: 4px;"></div>
      <p-accordion [multiple]="true">
        <p-accordionTab header="Michigan State University, 1996-2001" class="pageFont" style="font-size:12.0pt;" [(selected)]="educationSelected[0]">
          <b>Degree:</b> Bachelor’s Degree in Computer Science & Engineering
          <div class="top-text-margin">
            Computer Graphics, Operating Systems, Probability & Statistics for Engineering, Algorithms & Data Structures, Collaborative Design, Database Systems
          </div>
        </p-accordionTab>
        <p-accordionTab header="Angular 10 Training, 2020" class="pageFont" style="font-size:12.0pt;" [(selected)]="educationSelected[1]">
          <b>Degree:</b> Certificate
          <div class="top-text-margin">
            Debugging, Databinding, Directives, Services with Dependency Injection, Routing, Forms, Piping, HTTP Requests, Authentication, Route Protection, Dynamic Components, Modules, Deployment Strategies, and Angular Animations.
          </div>
        </p-accordionTab>
        <p-accordionTab header="Java Microservices Training, 2021" class="pageFont" style="font-size:12.0pt;" [(selected)]="educationSelected[2]">
          <div>
            Spring Boot, Spring Data JPA, Spring Web, H2, Swagger, Hal Explorer, Spring Cloud (Config, Gateway, Netflix Eureka, LoadBalancer, OpenFeign), Docker, Docker Compose, Kubernetes
          </div>
        </p-accordionTab>
      </p-accordion>
    </div>
  </div>
</div>
<br><br>
<p-dialog header="Angular 10 Certificate" [(visible)]="certDisplay" [modal]="true">
  <embed src="/assets/docs/Angular_10_Certificate.pdf" width="800px" height="800px" />
</p-dialog>
