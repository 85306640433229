<div>
  <div class="row">
    <div class="col-xs-12">
      <img src="https://www.allenedmonds.com/dw/image/v2/AAXI_PRD/on/demandware.static/-/Sites-allenedmonds-catalog/default/dwbff6785f/images/2.1/mens-shoes/fifthave-5735-walnut-angle-web.jpg?sw=2000&sh=2000&sm=fit"
           alt="5th Avenue Cap-Toe Oxford"
           style="max-height: 300px;" class="img-responsive">
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12" style="text-align: center;">
      <h5>5th Avenue Cap-Toe Oxford</h5>
    </div>
  </div>
</div>

